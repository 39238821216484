<!-- <ng-container *tuiLet="result$ | async as vm"> -->
<div class="search-results-container" *tuiLet="index$ | async as pageIndex">
  <div class="search-results">
    <app-search-item
      *ngFor="let item of items; let index = index"
      [item]="item"
      [pageNumber]="pageIndex || 0"
      [itemNumber]="index"
      [supplierNumber]="item.additionalInfo.sellerId"
      [supplierName]="item.additionalInfo.sellerInfo.name"
      [scheme]="result.searchResultCatScheme"
      (expand)="onItemExpand(pageIndex || 0, index)"
      (collapse)="onItemCollapse(pageIndex || 0, index)"></app-search-item>
  </div>
  <tui-pagination
    *ngIf="pagesCount > 1"
    class="search-results-pagination"
    [length]="pagesCount"
    [index]="pageIndex || 0"
    (indexChange)="goToPage($event)"></tui-pagination>

  <!-- <cdk-virtual-scroll-viewport
      autosize
      class="search-viewport"
      #scrollViewPort>
      <div *cdkVirtualFor="let item of vm!.results">
        <app-search-item [item]="item"></app-search-item>
      </div>
    </cdk-virtual-scroll-viewport> -->
</div>
<!-- </ng-container> -->
