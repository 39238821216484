<h3>Также ищут:</h3>
<div class="tags-container">
  <a
    tuiTag
    *ngFor="let query of queries"
    class="tag tui-space_top-2"
    status="custom"
    [hoverable]="true"
    [routerLink]="['/search']"
    [queryParams]="{ text: query }"
    [value]="query"
    (click)="onClick(query)"></a>
</div>
