<div class="title">
  Расскажите нам больше
  <tui-tooltip
    content="Опросы помогают нам становиться лучше - мы учитываем вашу обратную связь  в создании наших продуктов"
    describeId="button"
    direction="bottom"
    class=""></tui-tooltip>
</div>
<button
  tuiButton
  appearance="outline"
  size="m"
  *ngFor="let option of predefinedOptions"
  (click)="sendFeedback(option)">
  {{ option }}
</button>
<button tuiButton appearance="outline" size="m" (click)="onCustomOptionClick()">
  {{ customOption }}
</button>
<form [formGroup]="form" *ngIf="showTextarea">
  <tui-textarea
    formControlName="customText"
    class="tui-space_bottom-3"
    [tuiTextfieldSize]="'m'"
    >Расскажите нам больше...</tui-textarea
  >
  <button
    type="button"
    tuiButton
    appearance="custom"
    class="send-btn"
    size="m"
    (click)="sendCustomFeedback()">
    Отправить
  </button>
</form>
