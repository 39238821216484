import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceChangesChartComponent } from './components/price-changes-chart/price-changes-chart.component';
import { ChartCardComponent } from './components/chart-card/chart-card.component';
import { CurrentMarginCardComponent } from './components/current-margin-card/current-margin-card.component';
import { TuiSvgModule, TuiTooltipModule } from '@taiga-ui/core';
import { OneMetricCardComponent } from './components/one-metric-card/one-metric-card.component';
import { LastUpdateDateItemCardComponent } from './components/last-update-date-item-card/last-update-date-item-card.component';
import { ClosestQueriesCardComponent } from './components/closest-queries-card/closest-queries-card.component';
import { TuiTabsModule, TuiTagModule } from '@taiga-ui/kit';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { MaxMinPricesChartComponent } from './components/max-min-prices-chart/max-min-prices-chart.component';
import { PieChartCardComponent } from './components/pie-chart-card/pie-chart-card.component';
import { AveragePriceCategoryCardComponent } from './components/average-price-category-card/average-price-category-card.component';
import { ApproximateMarginCardComponent } from './components/approximate-margin-card/approximate-margin-card.component';
import { FreqDistributionCardComponent } from './components/freq-distribution-card/freq-distribution-card.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { PriceComparisonCardComponent } from './components/price-comparison-card/price-comparison-card.component';
import { MeanPriceChangesCardComponent } from './components/mean-price-changes-card/mean-price-changes-card.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { RouterModule } from '@angular/router';
import { IconModule } from '../core/components/icon/icon.module';

@NgModule({
  declarations: [
    PriceChangesChartComponent,
    ChartCardComponent,
    CurrentMarginCardComponent,
    OneMetricCardComponent,
    LastUpdateDateItemCardComponent,
    ClosestQueriesCardComponent,
    BarChartComponent,
    MaxMinPricesChartComponent,
    PieChartCardComponent,
    AveragePriceCategoryCardComponent,
    ApproximateMarginCardComponent,
    FreqDistributionCardComponent,
    DoughnutChartComponent,
    PriceComparisonCardComponent,
    MeanPriceChangesCardComponent,
    LineChartComponent,
  ],
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiTagModule,
    TuiTabsModule,
    TuiTooltipModule,
    RouterModule,
    IconModule,
  ],
  providers: [],
  exports: [
    PriceChangesChartComponent,
    CurrentMarginCardComponent,
    OneMetricCardComponent,
    LastUpdateDateItemCardComponent,
    ClosestQueriesCardComponent,
    BarChartComponent,
    MaxMinPricesChartComponent,
    PieChartCardComponent,
    AveragePriceCategoryCardComponent,
    ApproximateMarginCardComponent,
    FreqDistributionCardComponent,
    DoughnutChartComponent,
    ChartCardComponent,
    PriceComparisonCardComponent,
    MeanPriceChangesCardComponent,
  ],
})
export class AnalysisModule {}
