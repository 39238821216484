import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  webApp: WebApp;
  webView: any; // TODO add type

  constructor(
    @Inject(WINDOW) private window: Window,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.webApp = this.window?.Telegram?.WebApp;
    this.webView = (this.window?.Telegram as any)?.WebView;

    this.webApp?.enableClosingConfirmation();
  }

  getUserData(): WebAppUser | null {
    try {
      return JSON.parse(decodeURIComponent(this.webView?.initParams?.user));
    } catch (err) {
      return null;
    }
  }

  getLinkURLAuthData(): Observable<
    (WebAppUser & { hash: string } & { auth_date: string }) | null
  > {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params) => {
        if (!!params['id'] && !!params['hash']) {
          return {
            id: +params['id'],
            hash: params['hash'],
            first_name: params['first_name'],
            last_name: params['last_name'],
            username: params['username'],
            photo_url: params['photo_url'],
            auth_date: params['auth_date'],
          };
        }

        return null;
      }),
      tap(data => {
        if (data) {
          this.router.navigate([], {
            queryParams: null,
          });
        }
      })
    );
  }

  getTgHash(): string | null {
    return this.webView?.initParams.hash || null;
  }

  getAuthDate(): string | null {
    return this.webView?.initParams.auth_date || null;
  }

  getInitData(): string | null {
    return this.webApp?.initData;
  }

  isTgWebApp(): boolean {
    return !!this.webApp?.initData;
  }
}
