import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ISearchTotalAnalysis } from '../../models/search-result.model';

@Component({
  selector: 'app-search-result-analytics',
  templateUrl: './search-result-analytics.component.html',
  styleUrls: ['./search-result-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultAnalyticsComponent {
  @Input() data: ISearchTotalAnalysis;
  @Input() scheme: { [key: string]: Array<string> };

  @Output() closeAnalitics = new EventEmitter<void>();
  @Output() newSearch = new EventEmitter<string>();

  public get averagePriceCategoryLabels(): Array<string> {
    return Object.keys(this.data.averagePriceCategory);
  }

  public get averagePriceCategory(): Array<number> {
    return Object.values(this.data.averagePriceCategory);
  }

  onTagClick(text: string): void {
    this.newSearch.emit(text);
    this.closeAnalitics.emit();
  }
}
