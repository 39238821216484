import { Injectable } from '@angular/core';
import { IUserDataDTO } from '../models/user-data-dto.model';
import { IUserData } from '../models/user-data.model';

@Injectable()
export class UserDataMapper {
  fromDTO(data: IUserDataDTO): IUserData | null {
    if (!data) return null;
    return {
      tgId: data.tg_id,
      expirationDate: new Date(+data.expiration_date),
      firstName: data.first_name,
      photoUrl: data.photo_url,
    };
  }
}
