import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchItem } from '../../models/search-item.model';
import {
  SearchFiltersPreset,
  SearchResult,
} from '../../models/search-result.model';
import { TSearchResultSort } from '../../models/search-result.model';
import { SearchService } from '../../services/search.service';
import { map } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

export interface SearchResultsViewModel {
  results: Array<SearchItem>;
  sort: TSearchResultSort;
  filters: SearchFiltersPreset;
  text: string;
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent {
  @Input() items!: Array<SearchItem>;
  @Input() result!: SearchResult;

  index$ = this.searchService.page$.pipe(map(index => index - 1));

  get pagesCount(): number {
    return Math.ceil(this.result.itemsFound / this.result.pageSize);
  }

  constructor(
    private readonly searchService: SearchService,
    private readonly analyticsService: AnalyticsService
  ) {}

  goToPage(index: number): void {
    this.analyticsService.sendOpenPageEvent(index);
    this.searchService.updatePage(index + 1);
  }

  onItemExpand(pageIndex: number, itemIndex: number): void {
    this.analyticsService.sendExpandItemEvent(pageIndex, itemIndex);
  }

  onItemCollapse(pageIndex: number, itemIndex: number): void {
    this.analyticsService.sendCollapseItemEvent(pageIndex, itemIndex);
  }
}
