<img
  *ngIf="images.length === 0"
  class="search-item-img"
  [src]="'assets/images/no-image.png'"
  [alt]="alt" />
<tui-carousel
  [(index)]="caruselIndex"
  [style.--tui-carousel-padding]="'0'"
  [draggable]="true"
  *ngIf="previewImages.length > 0">
  <ng-container *ngFor="let item of previewImages; let index = index">
    <img
      appPlaceholderImg
      *tuiItem
      class="search-item-img search-item__img--clickable"
      [src]="item"
      [alt]="alt"
      loading="lazy"
      (click)="showPhotos($event)" />
  </ng-container>
</tui-carousel>
<tui-pagination
  (click)="$event.stopPropagation()"
  size="s"
  class="pagination"
  [length]="previewImages.length"
  [(index)]="caruselIndex"></tui-pagination>

<ng-template #preview let-preview>
  <tui-preview
    [zoomable]="false"
    (tuiSwipe)="onSwipe($event)"
    (click)="onPreviewClick($event)">
    <tui-preview-pagination
      [length]="images.length"
      [(index)]="sliderIndex"></tui-preview-pagination>

    <button
      icon="tuiIconClose"
      title="Close"
      tuiIconButton
      tuiPreviewAction
      type="button"
      (click)="preview.complete()"></button>

    <img alt="preview" [src]="previewContent" />
  </tui-preview>
</ng-template>
