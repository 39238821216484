<div class="search-result-analytics" *ngIf="data">
  <app-closest-queries-card
    [queries]="data.closestQueries"
    (tagClick)="onTagClick($event)"></app-closest-queries-card>

  <div class="col">
    <app-one-metric-card
      [title]="'Средняя цена у поставщиков'"
      [tooltip]="'Средняя цена товаров, представленных в поисковой выдаче'"
      [metric]="data.averagePrice"
      [isCurrency]="true"></app-one-metric-card>

    <app-average-price-category-card
      [title]="'Средняя цена категорий'"
      [tooltip]="'Средняя цена товаров, представленных в каждой категории'"
      [categories]="data.averagePriceCategory"
      [subcategories]="data.averagePriceSubcategory"
      [scheme]="scheme"></app-average-price-category-card>
  </div>

  <div class="col">
    <!-- Потенциальная прибыльность -->
    <app-approximate-margin-card
      [data]="data.approximateMargin"></app-approximate-margin-card>

    <!-- Предложения маркетплейсов -->
    <app-max-min-prices-chart
      [data]="data.extremumMpPrice"></app-max-min-prices-chart>
  </div>

  <div class="col">
    <!--Распределение товаров -->
    <app-freq-distribution-card
      [tooltip]="'Распределение товаров по категориям'"
      [categories]="data.categoriesFreq"
      [subcategories]="data.subcategoriesFreq"
      [scheme]="scheme"></app-freq-distribution-card>
  </div>
</div>
