import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { TelegramModule } from 'src/app/telegram/telegram.module';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-analysis-login',
  standalone: true,
  imports: [TelegramModule],
  templateUrl: './analysis-login.component.html',
  styleUrl: './analysis-login.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisLoginComponent {
  constructor(
    private readonly authService: AuthService,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<boolean>
  ) {
    this.authService.jwt$
      .pipe(
        untilDestroyed(this),
        filter(jwt => !!jwt)
      )
      .subscribe(() => {
        this.context.completeWith(true);
      });
  }

  onTgWidgetLogin(
    data: WebAppUser & { hash: string } & { auth_date: string }
  ): void {
    this.authService.auth(data);
  }
}
