<tui-push *tuiPush="open" class="cookie">
  <div class="cookie__container">
    <img class="cookie__img" src="assets/images/cookie.png" />
    <div class="cookie__content">
      <p>
        Мы используем
        <a href="https://altmetrics.ru/legal" target="_blank">cookies</a>, чтобы
        сайт работал лучше. Оставаясь на сайте вы соглашаетесь с
        <a href="https://altmetrics.ru/legal/license-agreement" target="_blank"
          >правилами лицензионного соглашения</a
        >
        и
        <a href="https://altmetrics.ru/legal/privacy-policy" target="_blank"
          >политикой конфиденциальности</a
        >.
      </p>
      <a
        tuiButton
        size="m"
        type="button"
        appearance="custom"
        (click)="onClose()"
        class="cookie__btn">
        OK
      </a>
      <!-- <button
        tuiButton
        size="m"
        type="button"
        appearance="custom"
        (click)="onClose()"
        class="cookie__btn">
        OK
      </button> -->
    </div>
  </div>
</tui-push>
