import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import {
  AvailableFilters,
  SearchFiltersPreset,
} from '../../models/search-result.model';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-search-filters-form',
  templateUrl: './search-filters-form.component.html',
  styleUrls: ['./search-filters-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFiltersFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() availableFilters: AvailableFilters;
  @Input() selectedFilters: SearchFiltersPreset | null = null;
  @Input() activeZone: any;

  @Output() updateFilters = new EventEmitter<SearchFiltersPreset>();
  @Output() closeFilters = new EventEmitter<void>();

  min = 0;
  max = 0;

  readonly sliderStep = 1;
  readonly quantum = 1;

  formGroup = new FormGroup({
    category: new FormControl<string | null>(
      this.selectedFilters?.category || null
    ),
    subcategories: new FormControl<Array<string>>(
      this.selectedFilters?.subcategories || []
    ),
    priceRange: new FormControl(
      this.selectedFilters?.priceRange
        ? [
            this.selectedFilters.priceRange.min,
            this.selectedFilters.priceRange.max,
          ]
        : [this.min, this.max]
    ),
    withPhotos: new FormControl<boolean>(
      this.selectedFilters?.withPhotos || true
    ),
    onlyDropshipping: new FormControl<boolean>(
      this.selectedFilters?.onlyDropshipping || false
    ),
    recentItemsOnly: new FormControl<boolean>(
      this.selectedFilters?.recentItemsOnly || false
    ),
    from: new FormControl(this.selectedFilters?.priceRange?.min || this.min),
    to: new FormControl(this.selectedFilters?.priceRange?.max || this.max),
  });

  get steps(): number {
    return (this.max - this.min) / this.sliderStep;
  }

  get categories(): Array<string> {
    return this.availableFilters?.catAndSubcat
      ? Object.keys(this.availableFilters.catAndSubcat)
      : [];
  }

  get subcategories(): Array<string> {
    if (!this.formGroup.controls.category.value) return [];
    return (
      this.availableFilters?.catAndSubcat[
        this.formGroup.controls.category.value
      ] || []
    );
  }

  get maxFrom(): number {
    return this.formGroup.controls.to.value!;
  }

  get minTo(): number {
    return this.formGroup.controls.from.value!;
  }

  applyFilters() {
    this.closeFilters.emit();
  }

  ngOnInit(): void {
    this.formGroup.controls.category.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => this.checkSelectedSubcategories());

    this.formGroup.controls.from.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value =>
        this.formGroup.controls.priceRange.setValue(
          [
            value || this.min,
            (this.formGroup.controls.priceRange.value as [number, number])[1],
          ],
          { emitEvent: false }
        )
      );

    this.formGroup.controls.to.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value =>
        this.formGroup.controls.priceRange.setValue(
          [
            (this.formGroup.controls.priceRange.value as [number, number])[0],
            value || this.max,
          ],
          { emitEvent: false }
        )
      );

    this.formGroup.controls.priceRange.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.formGroup.controls.from.setValue(value ? value[0] : this.min, {
          emitEvent: false,
        });
        this.formGroup.controls.to.setValue(value ? value[1] : this.max, {
          emitEvent: false,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedFilters = changes['selectedFilters'];
    const availableFilters = changes['availableFilters'];

    const {
      category,
      subcategories,
      withPhotos,
      priceRange,
      onlyDropshipping,
      recentItemsOnly,
    } = selectedFilters.currentValue;

    this.min = (availableFilters.currentValue as AvailableFilters)?.maxMinPrices
      .min;

    this.max = (availableFilters.currentValue as AvailableFilters)?.maxMinPrices
      .max;

    this.formGroup.controls.category.setValue(category);
    this.formGroup.controls.subcategories.setValue(subcategories);
    this.formGroup.controls.priceRange.setValue(
      priceRange ? [priceRange.min, priceRange.max] : [this.min, this.max]
    );
    this.formGroup.controls.withPhotos.setValue(withPhotos);
    this.formGroup.controls.onlyDropshipping.setValue(onlyDropshipping);
    this.formGroup.controls.recentItemsOnly.setValue(recentItemsOnly);
    this.formGroup.controls.from.setValue(
      priceRange ? priceRange.min : this.min
    );
    this.formGroup.controls.to.setValue(priceRange ? priceRange.max : this.max);
  }

  ngOnDestroy(): void {
    if (this.formGroup.dirty) {
      const {
        category,
        subcategories,
        withPhotos,
        priceRange,
        onlyDropshipping,
        recentItemsOnly,
      } = this.formGroup.value;

      this.updateFilters.emit({
        category,
        subcategories,
        withPhotos,
        onlyDropshipping,
        recentItemsOnly,
        priceRange:
          priceRange &&
          (priceRange[0] !== this.min || priceRange[1] !== this.max)
            ? {
                min: priceRange[0],
                max: priceRange[1],
              }
            : null,
      } as SearchFiltersPreset);
    }
  }

  private checkSelectedSubcategories(): void {
    const selectedSubcategories = this.formGroup.controls.subcategories.value;
    const selectedCategory = this.formGroup.controls.category.value;

    if (!selectedSubcategories) return;

    if (!selectedCategory) {
      this.formGroup.controls.subcategories.setValue([]);
      return;
    }

    const updatedSubcategories = selectedSubcategories.filter(value =>
      this.availableFilters.catAndSubcat[selectedCategory as string].includes(
        value
      )
    );

    this.formGroup.controls.subcategories.setValue(updatedSubcategories);
  }
}
