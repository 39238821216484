import { NgModule } from '@angular/core';
import { AuthService } from './services/auth.service';
import { FingerprintService } from './services/fingerprint.service';
import { UserDataMapper } from './services/user-data.mapper';
import { CommonService } from './services/common.service';
import { SeoService } from './services/seo.service';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  imports: [ClipboardModule],
  declarations: [],
  providers: [
    AuthService,
    FingerprintService,
    UserDataMapper,
    CommonService,
    SeoService,
  ],
})
export class WFCoreModule {}
