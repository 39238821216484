<app-chart-card
  [title]="'Потенциальная прибыльность'"
  [tooltip]="
    'Средняя цена у поставщиков - средняя цена товара на маркетплейсах'
  ">
  <div class="container" *ngFor="let item of data | keyvalue">
    <div class="price">
      <div class="price__value">
        {{ item.value.profitValue | number: '1.0-2' }} ₽
      </div>
      <div class="price__percent">
        <app-icon
          [iconSrc]="
            item.value.profitPercent > 0
              ? 'assets/images/arrow-up.svg'
              : 'assets/images/arrow-down.svg'
          "
          [size]="'s'"></app-icon>
        {{ item.value.profitPercent | number: '1.0-2' }}%
      </div>
    </div>
    <div class="logo">
      <img [src]="item.value.logo" />
    </div>
  </div>
</app-chart-card>
