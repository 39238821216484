import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-one-metric-card',
  templateUrl: './one-metric-card.component.html',
  styleUrls: ['./one-metric-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneMetricCardComponent {
  @Input() title: string;
  @Input() tooltip: string;
  @Input() metric: number | string;
  @Input() isPercent = false;
  @Input() isCurrency = false;
}
