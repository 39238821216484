import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchItemAnalysisInfo } from '../../models/search-item.model';

@Component({
  selector: 'app-search-item-analytics',
  templateUrl: './search-item-analytics.component.html',
  styleUrls: ['./search-item-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchItemAnalyticsComponent {
  @Input() analytics: SearchItemAnalysisInfo;
}
