import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { asapScheduler } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { DEFAULT_CHART_COLORS } from '../../constants/colors.const';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoughnutChartComponent implements AfterViewInit, OnChanges {
  @Input() data: { [key: string]: number };
  public chart: any;
  public chartId = 'pieChart' + uuid();

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => this.createChart());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.chart) return;

    const data = changes['data'];

    this.chart.data.labels = Object.keys(data.currentValue);
    this.chart.data.datasets[0].data = Object.values(data.currentValue);
    this.chart.update();
    this.updateLegend();
  }

  private createChart(): void {
    this.chart = new Chart(this.chartId, {
      type: 'doughnut',
      data: {
        labels: Object.keys(this.data),
        datasets: [
          {
            data: Object.values(this.data),
            backgroundColor: DEFAULT_CHART_COLORS,
          },
        ],
      },
      options: {
        responsive: true,
        cutout: '80%',
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    this.updateLegend();
  }

  private updateLegend(): void {
    let legendHtml = '';
    this.chart.data.labels.forEach((label: any, index: any) => {
      legendHtml +=
        '<div><span style="background-color:' +
        this.chart.data.datasets[0].backgroundColor[index] +
        '; display: inline-block; width: 36px; height: 12px; margin-right: 8px;"></span>' +
        `${label} (${this.chart.data.datasets[0].data[index]})` +
        '</div>';
    });

    document.getElementById(this.chartId + '__legend-container')!.innerHTML =
      legendHtml;
  }
}
