import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { CommonService } from './core/services/common.service';
import { SeoService } from './core/services/seo.service';
import { GoogleAnalyticsService } from './analytics/google-analytics.service';
import { environment } from '../environments/environment';
import { CookieService } from './cookie/cookie.service';
import { asyncScheduler } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

declare global {
  interface Window {
    Telegram: Telegram;
    onTelegramLogin: (data: any) => void;
    MSStream: unknown;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  isLoggedIn$ = this.authService.isLoggedIn$;
  cookieOpened = false;

  constructor(
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly seoService: SeoService,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.authService.checkLogin();
    this.commonService.setContentProperties();

    if (
      isPlatformBrowser(platformId) &&
      this.cookieService.needToShowCookiePush()
    ) {
      asyncScheduler.schedule(() => {
        this.cookieOpened = true;
      }, 5000);
    }
  }

  ngOnInit(): void {
    this.seoService.initDefaultMetaInformation();
    if (environment.production) {
      this.googleAnalyticsService.initialize();
    }
  }

  onCookiePushClose(): void {
    this.cookieOpened = false;
    this.cookieService.acceptCookie();
  }
}
