<div class="chart-card">
  <h2 *ngIf="title" class="chart-card__title">
    {{ title }}
    <tui-tooltip
      [content]="tooltip"
      describeId="button"
      direction="bottom"
      class=""></tui-tooltip>
  </h2>
  <h3 *ngIf="subtitle" class="chart-card__subtitle">{{ subtitle }}</h3>
  <ng-content></ng-content>
</div>
