<app-chart-card [title]="title" [tooltip]="tooltip">
  <div class="chart-container">
    <app-bar-chart
      [labels]="chartLabels"
      [data]="chartData"
      [yTick]="' ₽'"></app-bar-chart>
  </div>
  <div class="chips-container"></div>
  <tui-tabs
    [(activeItemIndex)]="activeItemIndex"
    [underline]="false"
    class="tabs"
    (activeItemIndexChange)="onIndexChanged($event)">
    <button tuiTab size="s">Все</button>
    <button size="s" *ngFor="let item of categoriesLabels" tuiTab>
      {{ item }}
    </button>
  </tui-tabs>
</app-chart-card>
