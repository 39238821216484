import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ISearchTotalAnalysis } from '../../models/search-result.model';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-search-result-analytics-desktop',
  templateUrl: './search-result-analytics-desktop.component.html',
  styleUrls: ['./search-result-analytics-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultAnalyticsDesktopComponent {
  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      string | null,
      {
        analytics: ISearchTotalAnalysis;
        scheme: { [key: string]: Array<string> };
      }
    >
  ) {}

  get data(): ISearchTotalAnalysis {
    return this.context.data.analytics;
  }

  get scheme(): { [key: string]: Array<string> } {
    return this.context.data.scheme;
  }

  onCloseAnalytics() {
    this.context.completeWith(null);
  }

  onSearch(text: string) {
    this.context.completeWith(text);
  }
}
