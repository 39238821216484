import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TuiButtonModule } from '@taiga-ui/core';
import { TuiPushModule } from '@taiga-ui/kit';

@Component({
  selector: 'app-cookie-msg',
  standalone: true,
  imports: [TuiPushModule, TuiButtonModule],
  templateUrl: './cookie-msg.component.html',
  styleUrl: './cookie-msg.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieMsgComponent {
  @Input() open = false;
  @Output() closePush = new EventEmitter<void>();

  onClose() {
    this.closePush.emit();
  }
}
