<form [formGroup]="form" action class="search-input-form">
  <tui-input
    formControlName="search"
    tuiTextfieldSize="m"
    [tuiTextfieldCleaner]="true"
    tuiTextfieldIconLeft="tuiIconSearch"
    [tuiTextfieldLabelOutside]="true"
    (keydown.enter)="onSearch()"
    (keyup.enter)="hideKeyboard()">
    Искать товары
  </tui-input>
  <button
    class="search-btn"
    type="button"
    tuiButton
    size="m"
    appearance="primary"
    (click)="onSearch()">
    Поиск
  </button>
</form>
