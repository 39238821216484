import { ChangeDetectionStrategy } from '@angular/core';
import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { TelegramService } from 'src/app/telegram/services/telegram/telegram.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  userData$ = this.authService.userData$;
  isLoggedIn$ = this.authService.isLoggedIn$;
  isTgWebApp = this.telegramService.isTgWebApp();

  constructor(
    private readonly authService: AuthService,
    private readonly telegramService: TelegramService
  ) {}

  logout(): void {
    this.authService.logout();
  }
}
