<h2>
  <label>Сгенерированное сообщение для поставщика</label>
  <button
    tuiIconButton
    type="button"
    appearance="icon"
    size="m"
    (click)="onClose()">
    <app-icon
      [iconSrc]="'assets/images/iconCloseLarge.svg'"
      [size]="'s'"></app-icon>
  </button>
</h2>
<div class="msg" [innerText]="msg"></div>
<div class="copy-msg-btn-wrapper">
  <button
    type="button"
    tuiButton
    size="m"
    appearance="custom"
    class="copy-msg-btn"
    [cdkCopyToClipboard]="msg"
    (cdkCopyToClipboardCopied)="onClipboardCopy()">
    {{ copyMsgText }}
  </button>
</div>
<label class="tui-space_top-4 tui-space_bottom-4">Контакты поставщика:</label>
<div class="contacts">
  <a
    *ngFor="let contact of contacts"
    [href]="contact.snUrl"
    (click)="onContactClick()"
    target="_blank"
    rel="nofollow noopener">
    <img class="social-icon" [src]="contact.snImageUrl" />
    {{ contact.snType }}
  </a>
</div>
