import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable, switchMap } from 'rxjs';
import { FingerprintService } from '../services/fingerprint.service';

@Injectable()
export class FingerprintInterceptor implements HttpInterceptor {
  constructor(private readonly fingerprintService: FingerprintService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.fingerprintService.getFingerprint().pipe(
      switchMap(fp => {
        const modifiedReq = req.clone({
          headers: req.headers.set('fingerprint', `${fp}`),
          withCredentials: true,
        });

        return next.handle(modifiedReq);
      })
    );
  }
}
