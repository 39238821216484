import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IUserData } from 'src/app/core/models/user-data.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoComponent {
  @Input() userData: IUserData;

  noPhoto: string = 'assets/images/no-photo.png';

  constructor() {
    // this.noPhoto = `assets/images/no-photo${
    //   new Date().getSeconds() % 2 === 0 ? '1' : '2'
    // }.png`;
  }
}
