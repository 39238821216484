import { SearchItem } from './search-item.model';
import { SellerInfo } from './seller.model';

export interface SearchResult {
  authorization: string;
  logged: boolean;
  status: TSearchResultStatus;
  searchId: string;
  requestId: string;
  itemsFound: number;
  sort: TSearchResultSort;
  filters: SearchFilters;
  searchResult: Array<SearchItem>;
  searchTotalAnalysis: ISearchTotalAnalysis | null;
  searchText: string;
  searchResultCatScheme: { [key: string]: Array<string> };
  searchSellersInfo: { [key: string]: SellerInfo };
  page: number;
  pageSize: number;
}

export interface SearchFilters {
  filtersPreset: SearchFiltersPreset;
  availableFilters: AvailableFilters;
}

export interface AvailableFilters {
  catAndSubcat: { [key: string]: Array<string> };
  maxMinPrices: {
    max: number;
    min: number;
  };
}

export interface SearchFiltersPreset {
  category: string | null;
  subcategories: Array<string>;
  withPhotos: boolean;
  priceRange: PriceRange | null;
  onlyDropshipping: boolean;
  recentItemsOnly: boolean;
}

export interface PriceRange {
  min: number;
  max: number;
}

export type TSearchResultStatus = 'Success' | 'Failed';

export function isSearchResultStatus(str: string): str is TSearchResultStatus {
  return str === 'Success' || str === 'Failed';
}

export type TSearchResultSort = 'asc' | 'desc';

export function isSearchResultSort(str: string): str is TSearchResultSort {
  return str === 'asc' || str === 'desc';
}

export interface ISearchTotalAnalysis {
  averagePrice: number;
  averagePriceCategory: { [key: string]: number };
  averagePriceSubcategory: { [key: string]: number };
  closestQueries: Array<string>;
  approximateMargin: { [key: string]: IMPApproximateMarginValue };
  extremumMpPrice: IExtremumMpPrice;
  categoriesFreq: { [key: string]: number };
  subcategoriesFreq: { [key: string]: number };
}

export interface IExtremumMpPrice {
  [key: string]: {
    maxPrice: number;
    meanPrice: number;
    minPrice: number;
  };
}

export interface IMPApproximateMarginValue {
  profitPercent: number;
  profitValue: number;
  logo: string;
}
