import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  Input,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { v4 as uuid } from 'uuid';
import { IExtremumMpPrice } from 'src/app/search/models/search-result.model';
import { asapScheduler } from 'rxjs';

@Component({
  selector: 'app-max-min-prices-chart',
  templateUrl: './max-min-prices-chart.component.html',
  styleUrls: ['./max-min-prices-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaxMinPricesChartComponent implements AfterViewInit {
  @Input() data: IExtremumMpPrice;
  public chart: any;
  public chartId = 'extremumMpPricesChart' + uuid();

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => this.createChart());
  }

  private createChart(): void {
    const maxPrices = Object.values(this.data).map(value => value.maxPrice);
    const minPrices = Object.values(this.data).map(value => value.minPrice);
    this.chart = new Chart(this.chartId, {
      data: {
        labels: Object.keys(this.data),
        datasets: [
          {
            type: 'bar',
            label: 'Цена макс',
            yAxisID: 'yAxis',
            data: maxPrices,
            backgroundColor: ['#0A84FF'],
          },
          {
            type: 'bar',
            label: 'Цена мин',
            yAxisID: 'yAxis',
            data: minPrices,
            backgroundColor: ['rgba(10, 132, 255, 0.6)'],
          },
        ],
      },
      options: {
        responsive: true,
        indexAxis: 'y',
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              font: {
                size: 12,
              },
            },
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              callback: (value, index, ticks) => {
                return value + ' ₽';
              },
            },
          },
        },
      },
    });
  }
}
