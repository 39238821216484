import {
  ChangeDetectionStrategy,
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import Chart from 'chart.js/auto';
import { asapScheduler } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements AfterViewInit, OnChanges {
  @Input() labels: Array<string> = [];
  @Input() data: Array<number> = [];
  @Input() yTick = '';

  public chart: any;
  public chartId = 'barChart' + uuid();

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => this.createChart());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.chart) return;

    const labelsChanges = changes['labels'];
    const data = changes['data'];

    this.chart.data.labels = labelsChanges.currentValue;
    this.chart.data.datasets[0].data = data.currentValue;
    this.chart.update();
  }

  private createChart(): void {
    this.chart = new Chart(this.chartId, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        elements: {
          bar: {
            backgroundColor: '#0a84ff',
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
            ticks: {
              callback: (value, index, ticks) => {
                return this.labels[index].length > 15
                  ? `${this.labels[index].slice(0, 15)}.`
                  : this.labels[index];
              },
            },
          },
          x: {
            beginAtZero: true,
            ticks: {
              callback: (value, index, ticks) => {
                return value + this.yTick;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }
}
