import { NgModule } from '@angular/core';
import { TuiButtonModule, TuiHintModule } from '@taiga-ui/core';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [HeaderComponent, UserInfoComponent],
  imports: [CommonModule, TuiButtonModule, TuiHintModule],
  providers: [],
  exports: [HeaderComponent],
})
export class HeaderModule {}
