import {
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TuiButtonModule,
} from '@taiga-ui/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  importProvidersFrom,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import { WFCoreModule } from './core/core.module';
import { TelegramModule } from './telegram/telegram.module';
import { SearchModule } from './search/search.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FingerprintInterceptor } from './core/interceptors/fingerprint.interceptor';
import { AccessInterceptor } from './core/interceptors/access.interceptor';
import { TuiSheetModule } from '@taiga-ui/addon-mobile';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { CookieMsgComponent } from './cookie/cookie-msg/cookie-msg.component';
import { CookieService } from './cookie/cookie.service';
import { FooterComponent } from './footer/footer.component';
import { MetrikaModule } from 'ng-yandex-metrika';
import { environment } from '../environments/environment';

const metrikaId: string | number = 'METRIKA_ID';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiButtonModule,
    HeaderModule,
    SearchModule,
    WFCoreModule,
    TelegramModule,
    TuiSheetModule,
    AngularSvgIconModule.forRoot(),
    CookieMsgComponent,
    FooterComponent,
    MetrikaModule.forRoot({
      id: metrikaId as unknown as number,
      webvisor: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    }),
  ],
  providers: [
    importProvidersFrom(
      MetrikaModule.forRoot([
        {
          id: metrikaId as unknown as number,
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        },
        { id: metrikaId as unknown as number },
      ])
    ),
    provideClientHydration(),
    //{ provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FingerprintInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
