import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-closest-queries-card',
  templateUrl: './closest-queries-card.component.html',
  styleUrls: ['./closest-queries-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClosestQueriesCardComponent {
  @Input() queries: Array<string> = [];

  @Output() tagClick = new EventEmitter<string>();

  onClick(text: string) {
    this.tagClick.emit(text);
  }
}
