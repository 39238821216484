import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ISearchTotalAnalysis } from '../../models/search-result.model';

@Component({
  selector: 'app-search-result-analytics-mobile',
  templateUrl: './search-result-analytics-mobile.component.html',
  styleUrls: ['./search-result-analytics-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultAnalyticsMobileComponent {
  @Input() data: ISearchTotalAnalysis;
  @Input() scheme: { [key: string]: Array<string> };

  @Output() closeAnalitics = new EventEmitter<void>();
  @Output() newSearch = new EventEmitter<string>();

  onCloseAnalytics() {
    this.closeAnalitics.emit();
  }

  onSearch(text: string) {
    this.newSearch.emit(text);
  }
}
