import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { SellerContact } from '../../models/seller.model';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-generated-msg-dialog',
  templateUrl: './generated-msg-dialog.component.html',
  styleUrls: ['./generated-msg-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneratedMsgDialogComponent {
  copyMsgText = 'Скопировать';

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        msg: string;
        contacts: Array<SellerContact>;
        pageNumber: number;
        itemNumber: number;
        supplierNumber: string;
        supplierName: string;
      }
    >,
    private readonly analyticsService: AnalyticsService
  ) {}

  get msg(): string {
    return this.context.data.msg;
  }

  get contacts(): Array<SellerContact> {
    return this.context.data.contacts;
  }

  onClipboardCopy() {
    this.copyMsgText = 'Сообщение скопировано';

    const { pageNumber, itemNumber, supplierNumber, supplierName } =
      this.context.data;
    this.analyticsService.sendCopySupplierMsgEvent(
      pageNumber,
      itemNumber,
      supplierNumber,
      supplierName
    );
  }

  onContactClick() {
    const { pageNumber, itemNumber, supplierNumber, supplierName } =
      this.context.data;

    this.analyticsService.sendOpenSupplierContactEvent(
      pageNumber,
      itemNumber,
      supplierNumber,
      supplierName
    );
  }

  onClose(): void {
    this.context.completeWith(false);
  }
}
