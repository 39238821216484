import { Inject, Injectable } from '@angular/core';
import { WINDOW, NAVIGATOR } from '@ng-web-apis/common';

@Injectable()
export class CommonService {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(NAVIGATOR) private navigator: Navigator
  ) {}

  public setContentProperties() {
    if (this.checkIsIOS()) {
      this.addMaximumScaleToMetaViewport();
    }
  }

  private addMaximumScaleToMetaViewport() {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      const re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content!)) {
        content = content!.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  }

  private checkIsIOS() {
    return (
      /iPad|iPhone|iPod/.test(this.navigator.userAgent) && !this.window.MSStream
    );
  }
}
