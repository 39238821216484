<div class="search-item" *ngIf="item.data as data">
  <a
    tuiLink
    class="search-item__supplier--mobile"
    target="_blank"
    rel="nofollow noopener"
    (click)="onGoToSellerClick()">
    <ng-container *ngTemplateOutlet="searchItemContainer"></ng-container>
  </a>
  <div class="search-item__supplier--desktop">
    <ng-container *ngTemplateOutlet="searchItemContainer"></ng-container>
  </div>
  <button
    class="search-item__expand-btn"
    [class.search-item__expand-btn--expanded]="expanded"
    tuiIconButton
    type="button"
    appearance="icon"
    size="s"
    icon="tuiIconChevronDown"
    (click)="toggle()"></button>
  <tui-expand [expanded]="expanded">
    <p
      class="description"
      *ngIf="item.additionalInfo.description"
      [innerText]="item.additionalInfo.description"></p>
    <tui-tabs
      [(activeItemIndex)]="activeTabIndex"
      [underline]="false"
      class="tabs">
      <button size="m" tuiTab>О продавце</button>
      <button size="m" tuiTab>Аналитика</button>
    </tui-tabs>
    <app-search-item-info
      *ngIf="activeTabIndex === 0 && expanded"
      [sellerInfo]="item.additionalInfo.sellerInfo"
      [scheme]="scheme"></app-search-item-info>
    <app-search-item-analytics
      *ngIf="activeTabIndex === 1 && expanded"
      [analytics]="item.analysisItemInfo"></app-search-item-analytics>
  </tui-expand>

  <ng-template #searchItemContainer>
    <div class="search-item__container">
      <app-search-item-images
        [images]="data.imgSrc"
        [alt]="data.name"></app-search-item-images>

      <div class="search-item__content">
        <div class="search-item__category">
          {{ data.category }} / {{ data.subcategory }}
          <tui-tag
            *ngIf="item.additionalInfo.sellerInfo.dropshipping"
            status="custom"
            [value]="'Drop'"
            class="drop-tag"
            size="s"></tui-tag>
        </div>
        <div class="search-item__name">{{ data.name }}</div>
        <div class="search-item__price">
          {{ data.price }} {{ 'RUB' | tuiCurrency }}
        </div>
        <div class="search-item__seller">
          {{ item.additionalInfo.sellerInfo.name }}
        </div>
      </div>
      <button
        class="search-item__supplier-btn tui-space_top-6"
        tuiButton
        type="button"
        size="m"
        appearance="custom"
        (click)="onGoToSellerClick()">
        К поставщику
      </button>
    </div>
  </ng-template>
</div>
