import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const sizeStyles = {
  s: {
    svgSize: 16,
    iconSize: 24,
  },
  m: {
    svgSize: 24,
    iconSize: 36,
  },
  l: {
    svgSize: 36,
    iconSize: 48,
  },
};

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() iconSrc: string;
  @Input() size: 's' | 'm' | 'l' = 's';
  @Input() svgSize = sizeStyles[this.size].svgSize;
  @Input() sizeOfIcon = sizeStyles[this.size].iconSize;

  public get svgStyles(): object {
    return { 'width.px': this.svgSize, 'height.px': this.svgSize };
  }

  public get iconSize(): number {
    return this.sizeOfIcon;
  }
}
