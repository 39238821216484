<app-header class="header"></app-header>
<ng-container
  *tuiLet="{
    isLoading: isLoading$ | async,
    result: result$ | async,
    preparedResult: preparedResult$ | async,
    hasFilters: hasFilters$ | async,
    selectedCatSubcatFilters: selectedCatSubcatFilters$ | async,
    selectedWithPhotoFilter: selectedWithPhotoFilter$ | async,
    selectedPriceRangeFilter: selectedPriceRangeFilter$ | async,
    selectedDropFilter: selectedDropFilter$ | async,
    selectedRecentItemsFilter: selectedRecentItemsFilter$ | async,
    selectedFiltersCount: selectedFiltersCount$ | async,
    textFromQueryParams: textFromQueryParams$ | async,
    isLoggedIn: isLoggedIn$ | async
  } as o">
  <app-search-input
    [text]="o.textFromQueryParams"
    (searchTextChanged)="
      onSearch($event, o.textFromQueryParams)
    "></app-search-input>
  <div
    class="filters-row"
    [class.filters-row--footer]="!o.result?.searchResult">
    <ng-container *ngIf="!!o.result?.searchResult && !isServer">
      <ng-container *ngIf="!o.isLoading && o.result!.searchTotalAnalysis">
        <button
          tuiButton
          type="button"
          size="m"
          appearance="footer-btn"
          class="analytics-btn btn--mobile"
          (click)="
            o.isLoggedIn
              ? openAnalytics(
                  o.result!.searchTotalAnalysis,
                  o.result!.searchResultCatScheme
                )
              : openAnalyticsLogin(
                  o.result!.searchTotalAnalysis,
                  o.result!.searchResultCatScheme
                )
          ">
          Аналитика
        </button>

        <button
          tuiButton
          type="button"
          size="m"
          appearance="custom-secondary"
          class="analytics-btn btn--desktop"
          (click)="
            o.isLoggedIn
              ? openAnalytics(
                  o.result!.searchTotalAnalysis,
                  o.result!.searchResultCatScheme
                )
              : openAnalyticsLogin(
                  o.result!.searchTotalAnalysis,
                  o.result!.searchResultCatScheme
                )
          ">
          Аналитика
          <app-icon
            class="tui-space_left-1"
            [iconSrc]="'assets/images/magicpen.svg'"
            [size]="'s'"></app-icon>
        </button>
      </ng-container>

      <div class="feedback--desktop">
        <ng-container *ngTemplateOutlet="feedback"></ng-container>
      </div>

      <button
        *ngIf="!o.isLoading && o.hasFilters"
        tuiIconButton
        type="button"
        appearance="footer-btn"
        size="m"
        class="filters-btn btn--mobile"
        (click)="toggleFiltersSheet()">
        <app-icon
          [iconSrc]="'assets/images/filters.svg'"
          [size]="'m'"></app-icon>
      </button>

      <div class="filters-container">
        <ng-container *ngIf="!o.isLoading && !!o.result?.searchResult?.length">
          <tui-hosted-dropdown
            #dropdown
            [tuiDropdownAlign]="'right'"
            [tuiDropdownMaxHeight]="200"
            [content]="sortDropDownContent"
            [(open)]="sortingDropdownOpen"
            class="sorting-btn btn--desktop">
            <button
              tuiButton
              type="button"
              appearance="custom-secondary"
              size="m">
              {{
                o.preparedResult!.sort === 'asc'
                  ? 'Сначала дешевые'
                  : 'Сначала дорогие'
              }}
              <app-icon
                class="sorting-btn__icon tui-space_left-1"
                [class.sorting-btn__icon--expanded]="sortingDropdownOpen"
                [iconSrc]="'assets/images/icon-chevron-down.svg'"
                [size]="'s'"></app-icon>
            </button>
          </tui-hosted-dropdown>

          <ng-template #sortDropDownContent>
            <tui-data-list>
              <button
                tuiOption
                size="s"
                (click)="onUpdateSortingDesktop('desc')">
                Сначала дешевые
              </button>
              <button
                tuiOption
                size="s"
                (click)="onUpdateSortingDesktop('asc')">
                Сначала дорогие
              </button>
            </tui-data-list>
          </ng-template>

          <button
            tuiIconButton
            type="button"
            appearance="footer-btn"
            size="m"
            class="sorting-btn btn--mobile"
            (click)="onUpdateSorting(o.preparedResult!.sort)">
            <app-icon
              [iconSrc]="
                o.preparedResult!.sort === 'asc'
                  ? 'assets/images/sort-down.svg'
                  : 'assets/images/sort-up.svg'
              "
              [size]="'m'"></app-icon>
          </button>
        </ng-container>

        <tui-hosted-dropdown
          *ngIf="!o.isLoading && o.hasFilters"
          #dropdown
          [tuiDropdownAlign]="'right'"
          [tuiDropdownMaxHeight]="500"
          [content]="content"
          [(open)]="filtersDropdownOpen"
          class="filters-btn btn--desktop">
          <button
            tuiButton
            type="button"
            appearance="custom-secondary"
            size="m">
            Фильтры
            <app-icon
              [iconSrc]="'assets/images/filters-desktop.svg'"
              [size]="'s'"
              class="tui-space_left-1"></app-icon>
          </button>
        </tui-hosted-dropdown>
        <ng-template #content let-activeZone>
          <app-search-filters-form
            class="desktop-filters-form"
            [availableFilters]="o.result!.filters.availableFilters"
            [selectedFilters]="o.preparedResult!.filters"
            [activeZone]="activeZone"
            (updateFilters)="updateFilters($event)"
            (closeFilters)="onCloseFilters()"></app-search-filters-form>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <p
    class="tui-space_top-6"
    *ngIf="!o.isLoading && o.result?.searchResult?.length">
    По запросу
    <span class="bold">{{ o.result!.searchText }}</span>
    найдено
    {{ o.result!.itemsFound + getFoundItemsTextEnd(o.result!.itemsFound) }}
  </p>

  <div
    class="current-filters tui-space_top-6"
    *ngIf="
      !o.isLoading && o.selectedFiltersCount && o.selectedFiltersCount > 0
    ">
    <tui-tag
      *ngIf="o.selectedFiltersCount > 1"
      class="tag"
      status="custom"
      [hoverable]="true"
      [value]="'Очистить всё'"
      (click)="onRemoveAllFilters()"></tui-tag>
    <tui-tag
      *ngFor="let filter of o.selectedCatSubcatFilters"
      status="primary"
      class="tag"
      [hoverable]="true"
      [removable]="true"
      (edited)="onEdited(filter, o.preparedResult!.filters)"
      [value]="filter">
    </tui-tag>
    <tui-tag
      *ngIf="o.selectedWithPhotoFilter"
      status="primary"
      class="tag"
      [hoverable]="true"
      [removable]="true"
      (edited)="onWithPhotoEdited(o.preparedResult!.filters)"
      [value]="'с фото'">
    </tui-tag>
    <tui-tag
      *ngIf="o.selectedDropFilter"
      status="primary"
      class="tag"
      [hoverable]="true"
      [removable]="true"
      (edited)="onDropOnlyEdited(o.preparedResult!.filters)"
      [value]="'Дропшиппинг'">
    </tui-tag>
    <tui-tag
      *ngIf="o.selectedRecentItemsFilter"
      status="primary"
      class="tag"
      [hoverable]="true"
      [removable]="true"
      (edited)="onRecentItemsEdited(o.preparedResult!.filters)"
      [value]="'За последний месяц'">
    </tui-tag>
    <tui-tag
      *ngIf="o.selectedPriceRangeFilter as priceRange"
      status="primary"
      class="tag"
      [hoverable]="true"
      [removable]="true"
      (edited)="onPriceRangeEdited(o.preparedResult!.filters)"
      [value]="'от ' + priceRange.min + ' до ' + priceRange.max + ' руб'">
    </tui-tag>
  </div>

  <div class="feedback--mobile">
    <ng-container *ngTemplateOutlet="feedback"></ng-container>
  </div>

  <div class="search-results">
    <tui-loader
      *ngIf="o.isLoading"
      class="tui-space_top-5 search-results__loader"
      [showLoader]="o.isLoading"
      size="xxl"
      [overlay]="true"></tui-loader>
    <app-search-results
      *ngIf="!o.isLoading && o.result?.searchResult?.length"
      [items]="o.result?.searchResult || []"
      [result]="o.result!"></app-search-results>

    <app-search-item-not-found
      *ngIf="
        !o.isLoading && !isServer && o.result?.searchResult?.length === 0
      "></app-search-item-not-found>
  </div>

  <ng-template [tuiSheetOptions]="sheetOptions" [(tuiSheet)]="filtersSheetOpen">
    <app-search-filters
      [availableFilters]="o.result!.filters.availableFilters"
      [selectedFilters]="o.preparedResult!.filters"
      (updateFilters)="updateFilters($event)"
      (closeFilters)="onCloseFilters()"></app-search-filters>
  </ng-template>

  <ng-template [tuiSheetOptions]="sheetOptions" [(tuiSheet)]="analyticsOpen">
    <app-search-result-analytics-mobile
      *ngIf="o.result!.searchTotalAnalysis"
      [data]="o.result!.searchTotalAnalysis"
      [scheme]="o.result!.searchResultCatScheme"
      (newSearch)="onSearch($event, '')"
      (closeAnalitics)="onCloseAnalytics()">
    </app-search-result-analytics-mobile>
  </ng-template>

  <ng-template #feedback>
    <tui-chip
      *ngIf="showFeedbackBtn"
      appearance="whiteblock"
      [style.border-radius.px]="30"
      [style.height.px]="40"
      class="feedback"
      >Оцените поисковую выдачу
      <button
        tuiIconButton
        [size]="'s'"
        type="button"
        appearance="icon"
        (click)="onLike(o.result!)">
        <app-icon
          [iconSrc]="'assets/images/thumbs-up.svg'"
          [size]="'s'"></app-icon>
      </button>
      <button
        tuiIconButton
        [size]="'s'"
        type="button"
        appearance="icon"
        (click)="onDislike(o.result!)">
        <app-icon
          [iconSrc]="'assets/images/thumbs-down.svg'"
          [size]="'s'"></app-icon>
      </button>
    </tui-chip>
  </ng-template>
</ng-container>
