import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { TelegramService } from 'src/app/telegram/services/telegram/telegram.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainPageComponent {
  isLoggedIn$ = this.authService.isLoggedIn$;
  isTgWebApp = this.telegramService.isTgWebApp();
  isServer = isPlatformServer(this.platformId);

  imageURL = 'IMAGE_URL';

  constructor(
    private readonly router: Router,
    private readonly telegramService: TelegramService,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  onSearch(text: string): void {
    this.router.navigate(['/search'], {
      queryParams: { text, withPhotos: 'true' },
    });
  }

  onTgWidgetLogin(
    data: WebAppUser & { hash: string } & { auth_date: string }
  ): void {
    this.authService.auth(data);
  }
}
