import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Inject,
  Injector,
  ViewChild,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { SearchItem } from '../../models/search-item.model';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import {
  PolymorpheusComponent,
  PolymorpheusContent,
} from '@tinkoff/ng-polymorpheus';
import { GeneratedMsgDialogComponent } from '../generated-msg-dialog/generated-msg-dialog.component';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchItemComponent {
  @Input() item: SearchItem;
  @Input() scheme: { [key: string]: Array<string> };

  @Input() pageNumber: number;
  @Input() itemNumber: number;
  @Input() supplierNumber: string;
  @Input() supplierName: string;

  @Output() expand = new EventEmitter<void>();
  @Output() collapse = new EventEmitter<void>();

  expanded = false;
  activeTabIndex = 0;

  index = 0;

  @ViewChild('preview')
  readonly preview?: TemplateRef<TuiDialogContext>;

  get previewContent(): PolymorpheusContent {
    return this.item.data.imgSrc[this.index];
  }

  constructor(
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private readonly analyticsService: AnalyticsService
  ) {}

  toggle(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.expand.emit();
    } else {
      this.collapse.emit();
    }
  }

  onGoToSellerClick(): void {
    const goToSellerDialog = this.dialogs.open(
      new PolymorpheusComponent(GeneratedMsgDialogComponent, this.injector),
      {
        data: {
          contacts: this.item.additionalInfo.sellerInfo.contacts,
          msg: `Добрый день!\n\nИнтересует товар "${this.item.data.name}".\nПодскажите, есть ли он в наличии, какова итоговая стоимость и условия доставки?\nТовар был найден через https://altmetrics.ru\n\nСпасибо!`,
          pageNumber: this.pageNumber,
          itemNumber: this.itemNumber,
          supplierNumber: this.supplierNumber,
          supplierName: this.supplierName,
        },
        dismissible: false,
        closeable: false,
        size: 's',
      }
    );
    goToSellerDialog.subscribe();
    this.analyticsService.sendOpenSupplierViewEvent(
      this.pageNumber,
      this.itemNumber,
      this.supplierNumber,
      this.supplierName
    );
  }
}
