import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  IPriceMpComparisonItem,
  IPriceMpComparisonItemData,
} from 'src/app/search/models/search-item.model';

@Component({
  selector: 'app-price-comparison-card',
  templateUrl: './price-comparison-card.component.html',
  styleUrls: ['./price-comparison-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComparisonCardComponent {
  @Input() data: IPriceMpComparisonItem;

  activeItemIndex = 0;

  public get marketPlaces(): Array<string> {
    return this.data ? Object.keys(this.data) : [];
  }

  public get currentMPData(): IPriceMpComparisonItemData {
    return this.data[this.marketPlaces[this.activeItemIndex]];
  }

  onIndexChanged(index: number): void {}
}
