import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-average-price-category-card',
  templateUrl: './average-price-category-card.component.html',
  styleUrls: ['./average-price-category-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AveragePriceCategoryCardComponent implements OnInit {
  @Input() title: string;
  @Input() tooltip: string;
  @Input() categories: { [key: string]: number };
  @Input() subcategories: { [key: string]: number };
  @Input() scheme: { [key: string]: Array<string> };

  activeItemIndex = 0;
  chartLabels: Array<string> = [];
  chartData: Array<number> = [];

  public get categoriesLabels(): Array<string> {
    return Object.keys(this.categories);
  }

  ngOnInit() {
    this.chartLabels = this.categoriesLabels;
    this.chartData = Object.values(this.categories);
  }

  onIndexChanged(index: number) {
    if (this.activeItemIndex === 0) {
      this.chartLabels = this.categoriesLabels;
      this.chartData = Object.values(this.categories);
      return;
    }

    const category = this.categoriesLabels[index - 1];
    const subcategoriesLabels = this.scheme[category] || [];
    const currentSubcategories = Object.entries(this.subcategories).filter(
      ([subcategory, value]) => subcategoriesLabels.includes(subcategory)
    );

    this.chartLabels = currentSubcategories.map(
      ([subcategory, value]) => subcategory
    );
    this.chartData = currentSubcategories.map(([subcategory, value]) => value);
  }
}
