import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMPApproximateMarginValue } from 'src/app/search/models/search-result.model';

@Component({
  selector: 'app-approximate-margin-card',
  templateUrl: './approximate-margin-card.component.html',
  styleUrls: ['./approximate-margin-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproximateMarginCardComponent {
  @Input() data: { [key: string]: IMPApproximateMarginValue };
}
