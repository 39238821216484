import {
  Component,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  OnInit,
  Inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit, OnChanges {
  @Input() text = '';
  @Output() searchTextChanged = new EventEmitter<string>();

  readonly form = new FormGroup({
    search: new FormControl<string>(''),
  });

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  ngOnInit(): void {
    if (this.text) {
      this.onSearch();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const textChanges = changes['text'];

    this.form.setValue({ search: textChanges.currentValue });
  }

  onSearch() {
    if (!this.form.controls.search.value) return;
    this.searchTextChanged.emit(this.form.controls.search.value);
  }

  hideKeyboard() {
    (this.document.activeElement as any).blur();
  }
}
