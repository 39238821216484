<tui-root>
  <div class="app-container">
    <!-- <app-header class="app-container__header"></app-header> -->
    <div class="app-container__content">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <tui-sheets-host ngProjectAs="tuiOverContent"></tui-sheets-host>
  <app-cookie-msg
    [open]="cookieOpened"
    (closePush)="onCookiePushClose()"></app-cookie-msg>
</tui-root>
