import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SellerInfo } from '../../models/seller.model';

@Component({
  selector: 'app-search-item-info',
  templateUrl: './search-item-info.component.html',
  styleUrls: ['./search-item-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchItemInfoComponent {
  @Input() sellerInfo: SellerInfo;
  @Input() scheme: { [key: string]: Array<string> };
}
