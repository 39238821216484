<div class="analytics-container" *ngIf="analytics">
  <app-price-comparison-card
    *ngIf="!!analytics.priceMpComparisonItem"
    [data]="analytics.priceMpComparisonItem"></app-price-comparison-card>

  <!-- <app-price-changes-chart
    *ngIf="analytics.priceChangeItem"
    [labels]="analytics.priceChangeItem.labels"
    [data]="analytics.priceChangeItem.data"></app-price-changes-chart> -->

  <app-chart-card
    [title]="'Дата обновления'"
    [tooltip]="'Дата последнего обновления информации о товаре'">
    <div class="chart-card-date">
      {{ analytics.lastUpdateDateItem | date: 'd.MM.yyyy' }}
    </div>
  </app-chart-card>
</div>
