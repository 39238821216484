import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  Inject,
  AfterViewInit,
} from '@angular/core';
import {
  AvailableFilters,
  SearchFiltersPreset,
} from '../../models/search-result.model';
import { DOCUMENT } from '@angular/common';
import { asapScheduler } from 'rxjs';

@Component({
  selector: 'app-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFiltersComponent implements AfterViewInit {
  @Input() availableFilters: AvailableFilters;
  @Input() selectedFilters: SearchFiltersPreset | null = null;

  @Output() updateFilters = new EventEmitter<SearchFiltersPreset>();
  @Output() closeFilters = new EventEmitter<void>();

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => (this.document.activeElement as any).blur());
  }

  onUpdateFilters(filtersPreset: SearchFiltersPreset): void {
    this.updateFilters.emit(filtersPreset);
  }

  onCloseFilters(): void {
    this.closeFilters.emit();
  }
}
