import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ISearchResultDTO } from '../models/search-dto.model';
import { SearchMapper } from './search.mapper';
import {
  SearchFiltersPreset,
  SearchResult,
  TSearchResultSort,
} from '../models/search-result.model';
import { TuiAlertService } from '@taiga-ui/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable()
export class SearchApiService {
  private readonly apiUrl = environment.apiURL;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly searchMapper: SearchMapper,
    private readonly authService: AuthService,
    @Inject(TuiAlertService) private readonly alertsService: TuiAlertService
  ) {}

  search(
    text: string,
    page: number,
    pageSize: number,
    filters?: SearchFiltersPreset,
    sort?: TSearchResultSort,
    searchId?: string
  ): Observable<SearchResult | null> {
    return this.httpClient
      .post<ISearchResultDTO>(`${this.apiUrl}/search`, {
        search_id: searchId,
        search_text: text,
        sort: sort,
        filters: {
          category: filters?.category,
          subcategory: filters?.subcategories,
          with_photos: filters?.withPhotos || false,
          price_range: filters?.priceRange || undefined,
          only_dropshipping: filters?.onlyDropshipping || false,
          recent_items_only: filters?.recentItemsOnly || false,
        },
        page: page,
        page_size: pageSize,
      })
      .pipe(
        tap(result => {
          this.authService.checkJwt(result.Authorization);
        }),
        map(result => this.searchMapper.fromDTO(result)),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 403) {
            this.authService.logout();
            this.alertsService
              .open('Сервис находится в режиме закрытого тестирования', {
                status: 'error',
                label: 'Ошибка доступа',
              })
              .subscribe();
          } else if (error.status === 429) {
            this.alertsService
              .open(
                'Извините, вы отправили слишком много запросов. Пожалуйста, попробуйте еще раз позднее.',
                {
                  status: 'error',
                  label: 'Превышен лимит',
                }
              )
              .subscribe();
          }
          return of(this.searchMapper.fromDTO(null));
        })
      );
  }
}
