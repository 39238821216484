<h2>
  <label>Фильтры</label>
  <button
    tuiIconButton
    type="button"
    appearance="icon"
    size="m"
    (click)="onCloseFilters()">
    <app-icon
      [iconSrc]="'assets/images/iconCloseLarge.svg'"
      [size]="'s'"></app-icon>
  </button>
</h2>
<app-search-filters-form
  [availableFilters]="availableFilters"
  [selectedFilters]="selectedFilters"
  (updateFilters)="onUpdateFilters($event)"
  (closeFilters)="onCloseFilters()"></app-search-filters-form>
