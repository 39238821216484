import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchComponent } from './components/search/search.component';
import {
  TuiButtonModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiTextfieldControllerModule,
  TuiSvgModule,
  TuiHostedDropdownModule,
  TuiLabelModule,
  TuiExpandModule,
  TuiHintModule,
  TuiDialogModule,
  TuiTooltipModule,
} from '@taiga-ui/core';
import {
  TuiInputModule,
  TuiPaginationModule,
  TuiRadioBlockModule,
  TuiTabsModule,
  TuiTagModule,
  TuiInputRangeModule,
  TuiToggleModule,
  TuiRangeModule,
  TuiInputNumberModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SearchService } from './services/search.service';
import { SearchApiService } from './services/search-api.service';
import {
  TuiActiveZoneModule,
  TuiLetModule,
  TuiSwipeModule,
} from '@taiga-ui/cdk';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchItemComponent } from './components/search-item/search-item.component';
import { TuiCurrencyPipeModule } from '@taiga-ui/addon-commerce';
import { SearchItemNotFoundComponent } from './components/search-item-not-found/search-item-not-found.component';
import { TuiBlockStatusModule } from '@taiga-ui/layout';
import { SearchMapper } from './services/search.mapper';
import { TuiDataListModule } from '@taiga-ui/core';
import {
  TuiDataListWrapperModule,
  TuiSelectModule,
  TuiMultiSelectModule,
  TuiRadioLabeledModule,
  TuiCarouselModule,
  TuiPushModule,
} from '@taiga-ui/kit';
import { TuiSheetModule } from '@taiga-ui/addon-mobile';
import { SearchFiltersComponent } from './components/search-filters/search-filters.component';
import { SearchParamsService } from './services/search-params.service';
import { SearchItemAnalyticsComponent } from './components/search-item-analytics/search-item-analytics.component';
import { SearchItemInfoComponent } from './components/search-item-info/search-item-info.component';
import { AnalysisModule } from '../analysis/analysis.module';
import { SearchResultAnalyticsComponent } from './components/search-result-analytics/search-result-analytics.component';
import { SellerAnalyticsComponent } from './components/seller-analytics/seller-analytics.component';
import { TelegramModule } from '../telegram/telegram.module';
import { SearchSortingComponent } from './components/search-sorting/search-sorting.component';
import { IconModule } from '../core/components/icon/icon.module';
import { SearchFiltersFormComponent } from './components/search-filters-form/search-filters-form.component';
import { SearchResultAnalyticsDesktopComponent } from './components/search-result-analytics-desktop/search-result-analytics-desktop.component';
import { SearchResultAnalyticsMobileComponent } from './components/search-result-analytics-mobile/search-result-analytics-mobile.component';
import { GeneratedMsgDialogComponent } from './components/generated-msg-dialog/generated-msg-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MainPageComponent } from './components/main-page/main-page.component';
import { TuiPreviewModule } from '@taiga-ui/addon-preview';
import { SearchItemImagesComponent } from './components/search-item-images/search-item-images.component';
import { PlaceholderImgDirective } from './directives/placeholder-img.directive';
import { TuiChipModule } from '@taiga-ui/experimental';
import { SearchDislikeComponent } from './components/search-dislike/search-dislike.component';
import { HeaderModule } from '../header/header.module';
import { AnalysisLoginComponent } from '../analysis/components/analysis-login/analysis-login.component';

@NgModule({
  declarations: [
    SearchComponent,
    SearchInputComponent,
    SearchResultsComponent,
    SearchItemComponent,
    SearchItemNotFoundComponent,
    SearchFiltersComponent,
    SearchSortingComponent,
    SearchItemAnalyticsComponent,
    SearchItemInfoComponent,
    SearchResultAnalyticsComponent,
    SellerAnalyticsComponent,
    SearchFiltersFormComponent,
    SearchResultAnalyticsDesktopComponent,
    SearchResultAnalyticsMobileComponent,
    GeneratedMsgDialogComponent,
    MainPageComponent,
    SearchItemImagesComponent,
    SearchDislikeComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    TuiButtonModule,
    TuiInputModule,
    ReactiveFormsModule,
    FormsModule,
    TuiTextfieldControllerModule,
    TuiLetModule,
    TuiLoaderModule,
    TuiCurrencyPipeModule,
    TuiLinkModule,
    TuiBlockStatusModule,
    TuiSvgModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    TuiSelectModule,
    TuiHostedDropdownModule,
    TuiSheetModule,
    TuiMultiSelectModule,
    TuiLabelModule,
    TuiExpandModule,
    TuiTabsModule,
    TuiHintModule,
    TuiPaginationModule,
    AnalysisModule,
    TelegramModule,
    TuiRadioLabeledModule,
    TuiRadioBlockModule,
    TuiTagModule,
    TuiInputRangeModule,
    TuiRangeModule,
    IconModule,
    TuiActiveZoneModule,
    TuiLetModule,
    TuiDialogModule,
    ClipboardModule,
    TuiToggleModule,
    TuiPreviewModule,
    TuiCarouselModule,
    TuiSwipeModule,
    TuiInputNumberModule,
    TuiPushModule,
    TuiChipModule,
    TuiTextareaModule,
    TuiTooltipModule,
    PlaceholderImgDirective,
    HeaderModule,
    AnalysisLoginComponent,
  ],
  providers: [
    SearchService,
    SearchApiService,
    SearchMapper,
    SearchParamsService,
  ],
  exports: [SearchComponent],
})
export class SearchModule {}
