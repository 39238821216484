<footer class="footer">
  <a
    class="footer__link footer__altmetrics"
    href="https://altmetrics.ru"
    target="_blank"
    >© 2023-2024 ООО «Альтметрикс»</a
  >
  <div class="footer__social">
    <a href="https://t.me/altmetrics" target="_blank"
      ><img
        src="assets/images/social/tg.svg"
        alt="аккаунт Telegram"
        class="social-icon"
    /></a>
    <a href="https://vk.com/altmetrics" target="_blank"
      ><img
        src="assets/images/social/vk.svg"
        alt="аккаунт Вконтакте"
        class="social-icon"
    /></a>
    <a href="https://dzen.ru/altmetrics" target="_blank"
      ><img
        src="assets/images/social/dzen.svg"
        alt="аккаунт Дзен"
        class="social-icon"
    /></a>
  </div>
  <a
    class="footer__link footer__politics"
    href="https://altmetrics.ru/legal/privacy-policy"
    target="_blank"
    >Политика конфиденциальности</a
  >
  <a
    class="footer__link footer__license"
    href="https://altmetrics.ru/legal/license-agreement"
    target="_blank"
    >Лицензионное соглашение</a
  >
  <a href="https://sk.ru/" target="_blank">
    <img
      class="footer__skolkovo"
      src="assets/images/skolkovo.svg"
      alt="Участник проекта 'Сколково'"
  /></a>
</footer>
