import { Injectable } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Observable, from, map, shareReplay, switchMap } from 'rxjs';

@Injectable()
export class FingerprintService {
  getFingerprint(): Observable<string> {
    return from(FingerprintJS.load()).pipe(
      switchMap(agent =>
        from(agent.get()).pipe(
          map(data => data.visitorId),
          shareReplay(1)
        )
      )
    );
  }
}
