<div class="top">
  <app-header class="header"></app-header>
  <app-search-input (searchTextChanged)="onSearch($event)"></app-search-input>

  <div class="banners tui-space_top-4">
    <a href="https://altmetrics.ru/guide" target="_blank" class="banner guide">
      <app-icon
        [iconSrc]="'assets/images/icon-link.svg'"
        [svgSize]="28"
        [sizeOfIcon]="28"></app-icon>
      <span class="guide__text">Инструкция по использованию</span>
    </a>
    <a
      href="https://altmetrics.ru/"
      target="_blank"
      class="altmetrics_container">
      <img
        appPlaceholderImg
        class="banner altmetrics"
        [src]="imageURL + '/social/main_banner.png'" />
    </a>
    <div class="tg-links">
      <a href="https://t.me/altmetrics_manager" target="_blank">
        <div class="banner tg-ask">
          <div>
            <span class="banner__text tg-ask__text">Задать вопрос</span>
          </div>
          <span class="banner__text tg-ask__text">...</span>
        </div>
      </a>
      <a href="https://t.me/+cKz2Qi-0FVk4N2Ri" target="_blank">
        <div class="banner tg-channel">
          <span class="banner__text tg-channel__text">Наш телеграм канал</span>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="tg-widget">
  <app-telegram-login-widget
    *ngIf="!isTgWebApp && (isLoggedIn$ | async) === false && !isServer"
    (login)="onTgWidgetLogin($event)"></app-telegram-login-widget>
</div>
