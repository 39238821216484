import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import {
  SearchFiltersPreset,
  TSearchResultSort,
} from '../models/search-result.model';

@Injectable()
export class SearchParamsService {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  getQueryParams(): Observable<Params> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params) => ({
        text: params['text'] || '',
        sort: params['sort'] || 'asc',
        category: params['category'] ? JSON.parse(params['category']) : null,
        subcategories: params['subcategories']
          ? JSON.parse(params['subcategories'])
          : [],
        withPhotos: params['withPhotos']
          ? JSON.parse(params['withPhotos'])
          : false,
        priceRange: params['priceRange']
          ? JSON.parse(params['priceRange'])
          : null,
        onlyDropshipping: params['onlyDropshipping']
          ? JSON.parse(params['onlyDropshipping'])
          : false,
        recentItemsOnly: params['recentItemsOnly']
          ? JSON.parse(params['recentItemsOnly'])
          : false,
      }))
    );
  }

  getFiltersPreset(): Observable<SearchFiltersPreset> {
    return this.getQueryParams().pipe(
      map((params: Params) => ({
        category: params['category'],
        subcategories: params['subcategories'],
        withPhotos: params['withPhotos'],
        priceRange: params['priceRange'],
        onlyDropshipping: params['onlyDropshipping'],
        recentItemsOnly: params['recentItemsOnly'],
      }))
    );
  }

  getText(): Observable<string> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params) => {
        return (params['text'] || '') as string;
      })
    );
  }

  getSorting(): Observable<TSearchResultSort> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params) => {
        return (params['sort'] || 'asc') as TSearchResultSort;
      })
    );
  }

  updateQueryParams(queryParams: Params): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  clearQueryParams(): void {
    this.router.navigate([], {
      queryParams: null,
    });
  }
}
