import { Injectable } from '@angular/core';
import { AnalyticsEvent } from './analytics-event.type';
import { IAnalyticsService } from './analytics-service.interface';

// eslint-disable-next-line no-var
// eslint-disable-next-line @typescript-eslint/ban-types
declare let ym: Function;

@Injectable({
  providedIn: 'root',
})
export class YandexAnalyticsService implements IAnalyticsService {
  private metrikaId: string | number = 'METRIKA_ID';

  constructor() {}

  public event(action: AnalyticsEvent, params?: { [key: string]: string }) {
    if (typeof ym === 'undefined') return;
    ym(this.metrikaId, 'reachGoal', action, params && { ...params });
  }
}
