import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable()
export class SeoService {
  constructor(
    private readonly meta: Meta,
    private readonly title: Title
  ) {}

  initDefaultMetaInformation() {
    this.title.setTitle('Altmetrics app');

    this.meta.addTags([
      //{name: 'robots', content: 'index, follow'},
      {
        name: 'description',
        content:
          'Веб-приложение для оптово-розничной торговли. Находи лучшие предложения от поставщиков, которые подтверждены понятной аналитикой',
      },
      { name: 'og:locale', content: 'ru_RU' },
      { name: 'og:title', content: 'Altmetrics' },
      {
        name: 'og:description',
        content:
          'Веб-приложение для оптово-розничной торговли. Находи лучшие предложения от поставщиков, которые подтверждены понятной аналитикой',
      },
      { name: 'og:image', content: `assets/images/logo.png` },
      { name: 'og:site_name', content: 'Altmetrics' },
    ]);
  }

  setMetaData(metaData: MetaDefinition[]) {
    metaData?.forEach(data => {
      this.meta.updateTag(data);
    });
  }
}
