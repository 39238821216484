<app-chart-card [title]="'Распределение товаров'" [tooltip]="tooltip">
  <div class="chart-container">
    <app-doughnut-chart [data]="chartData"></app-doughnut-chart>
  </div>
  <div class="chips-container"></div>
  <tui-tabs
    [(activeItemIndex)]="activeItemIndex"
    [underline]="false"
    class="tabs"
    (activeItemIndexChange)="onIndexChanged($event)">
    <button tuiTab size="s">Все</button>
    <button size="s" *ngFor="let item of categoriesLabels" tuiTab>
      {{ item }}
    </button>
  </tui-tabs>
</app-chart-card>
