<app-chart-card
  [title]="'Потенциальная прибыльность'"
  [tooltip]="
    'Цена товара - минимальная/средняя/максимальная цена на маркетплейсах'
  ">
  <div class="data-container" *ngIf="currentMPData">
    <ng-container
      *ngTemplateOutlet="
        rowTemplate;
        context: { data: currentMPData.toMpMin, title: 'Минимум' }
      "></ng-container>
    <ng-container
      *ngTemplateOutlet="
        rowTemplate;
        context: { data: currentMPData.toMpMean, title: 'Среднее' }
      "></ng-container>
    <ng-container
      *ngTemplateOutlet="
        rowTemplate;
        context: { data: currentMPData.toMpMax, title: 'Максимум' }
      "></ng-container>
  </div>
  <tui-tabs
    [(activeItemIndex)]="activeItemIndex"
    [underline]="false"
    class="tabs"
    (activeItemIndexChange)="onIndexChanged($event)">
    <button size="s" *ngFor="let mp of marketPlaces" tuiTab>
      {{ mp }}
    </button>
  </tui-tabs>
</app-chart-card>

<ng-template #rowTemplate let-data="data" let-title="title">
  <div class="title">{{ title }}</div>
  <div>{{ data.value }} ₽</div>
  <img
    [src]="
      data.value > 0
        ? 'assets/images/arrow-up.svg'
        : 'assets/images/arrow-down.svg'
    " />
  <div>{{ data.percent | number: '1.0-2' }}%</div>
</ng-template>
