import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, defer } from 'rxjs';
import {
  take,
  finalize,
  switchMap,
  map,
  combineLatestWith,
  filter,
} from 'rxjs/operators';
import { SearchApiService } from './search-api.service';
import { SearchResult } from '../models/search-result.model';
import { SearchParamsService } from './search-params.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { MetaDefinition } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable()
export class SearchService {
  private resultsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  private isLoadingSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private currentSearchId = '';

  private pageSubject: BehaviorSubject<number> = new BehaviorSubject(1);
  private pageSizeSubject: BehaviorSubject<number> = new BehaviorSubject(25); // TODO

  public textFromQueryParams$ = this.searchParamsService
    .getQueryParams()
    .pipe(map(params => params['text'] || ''));

  get isLoading$(): Observable<boolean> {
    return this.isLoadingSubject.asObservable();
  }

  get result$(): Observable<SearchResult> {
    return this.resultsSubject.asObservable();
  }

  get page$(): Observable<number> {
    return this.pageSubject.asObservable();
  }

  get pageSize$(): Observable<number> {
    return this.pageSizeSubject.asObservable();
  }

  constructor(
    private readonly searchApiService: SearchApiService,
    private readonly searchParamsService: SearchParamsService,
    private readonly seoService: SeoService,
    private readonly authService: AuthService,
    @Inject(WINDOW) private window: Window
  ) {}

  setLoading(value: boolean): void {
    this.isLoadingSubject.next(value);
  }

  newSearch(text: string, clearFilters = true) {
    this.currentSearchId = '';
    this.pageSubject.next(1);

    if (text) {
      this.searchParamsService.updateQueryParams(
        clearFilters
          ? {
              text,
              category: null,
              subcategories: null,
              withPhotos: true,
              priceRange: null,
            }
          : { text }
      );
      this.search();
    } else {
      this.searchParamsService.clearQueryParams();
      this.resultsSubject.next([]);
    }
  }

  updateSearch() {
    this.search();
  }

  updatePage(page: number): void {
    this.pageSubject.next(page);
    this.search();
  }

  private search(): void {
    defer(() => {
      this.setLoading(true);
      this.window.scrollTo(0, 0);

      return this.searchParamsService.getFiltersPreset().pipe(
        combineLatestWith(
          this.searchParamsService.getText(),
          this.searchParamsService.getSorting(),
          this.page$,
          this.pageSize$,
          this.authService.isAuthChecked$
        ),
        filter(
          ([filters, text, sort, page, pageSize, isAuthChecked]) =>
            !!isAuthChecked
        ),
        switchMap(([filters, text, sort, page, pageSize]) =>
          this.searchApiService.search(
            text,
            page,
            pageSize,
            filters,
            sort,
            this.currentSearchId
          )
        ),
        finalize(() => this.setLoading(false)),
        take(1)
      );
    }).subscribe(results => {
      this.currentSearchId = results?.searchId || '';
      this.resultsSubject.next(results);
      this.seoService.setMetaData(this.getMetaTagsForSearchResults(results));
    });
  }

  private getMetaTagsForSearchResults(
    results: SearchResult | null
  ): MetaDefinition[] {
    if (!results?.searchText) return [];

    const titleContent = `Altmetrics | ${results?.searchText}`;
    const descriptionContent = `Лучшие предложения от поставщиков по запросу "${results?.searchText}". Простая и понятная аналитика`;

    const meta = [
      {
        name: 'title',
        content: titleContent,
      },
      {
        name: 'description',
        content: descriptionContent,
      },
      {
        name: 'og:title',
        content: titleContent,
      },
      {
        name: 'og:description',
        content: descriptionContent,
      },
    ];
    return meta;
  }
}
