import { Injectable } from '@angular/core';
import {
  IAnalysisItemInfoDTO,
  ISearchResultDTO,
  ISearchTotalAnalysisDTO,
  ISellerAnalysisDTO,
  ISellerContactDTO,
  ISellerInfoDTO,
} from '../models/search-dto.model';
import {
  ISearchTotalAnalysis,
  SearchResult,
  isSearchResultSort,
  isSearchResultStatus,
} from '../models/search-result.model';
import {
  ISellerAnalytics,
  SellerContact,
  SellerInfo,
} from '../models/seller.model';
import {
  SearchItemAdditionalData,
  SearchItemAnalysisInfo,
  SearchItemData,
} from '../models/search-item.model';
import { ISearchItemAdditionalInfoDTO } from '../models/search-dto.model';
import { ISearchItemDataDTO } from '../models/search-dto.model';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale/ru';

@Injectable()
export class SearchMapper {
  fromDTO(data: ISearchResultDTO | null): SearchResult | null {
    if (!data)
      return {
        authorization: '',
        logged: false,
        status: 'Failed',
        searchId: '',
        requestId: '',
        sort: 'asc',
        filters: {
          filtersPreset: {
            category: null,
            subcategories: [],
            withPhotos: false,
            priceRange: null,
            onlyDropshipping: false,
            recentItemsOnly: false,
          },
          availableFilters: {
            catAndSubcat: {},
            maxMinPrices: {
              max: 0,
              min: 0,
            },
          },
        },
        searchResult: [],
        searchTotalAnalysis: null,
        searchText: '',
        searchResultCatScheme: {},
        searchSellersInfo: {},
        page: 1,
        pageSize: 0,
        itemsFound: 0,
      };

    const sellersInfo = this.searchSellersInfoFromDTO(data.search_sellers_info);
    const {
      category,
      subcategory,
      with_photos,
      price_range,
      only_dropshipping,
      recent_items_only,
    } = data.filters.filters_preset;

    return {
      authorization: data.Authorization,
      logged: data.Logged,
      status: isSearchResultStatus(data.status) ? data.status : 'Failed',
      searchId: data.search_id,
      requestId: data.request_id,
      itemsFound: data.items_found || 0,
      sort: isSearchResultSort(data.sort) ? data.sort : 'asc',
      filters: {
        filtersPreset: {
          category: category,
          subcategories: subcategory,
          withPhotos: with_photos,
          priceRange: price_range,
          onlyDropshipping: only_dropshipping,
          recentItemsOnly: recent_items_only,
        },
        availableFilters: {
          catAndSubcat: data.filters.available_filters.cat_subcat,
          maxMinPrices: data.filters.available_filters.max_min_prices,
        },
      },
      searchResult: data.search_result.map(item => ({
        rowId: item.row_id,
        data: this.searchItemDataFromDTO(item.row_data),
        additionalInfo: this.additionalInfoFromDTO(
          item.additional_info,
          sellersInfo[item.additional_info.seller_id]
        ),
        analysisItemInfo: this.analysisItemInfoFromDTO(item.analysis_item_info),
      })),
      searchTotalAnalysis: this.searchTotalAnalysisFromSTO(
        data.search_total_analysis!
      ),
      searchText: data.search_text,
      searchResultCatScheme: data.search_result_cat_scheme,
      searchSellersInfo: sellersInfo,
      page: data.page,
      pageSize: data.page_size,
    };
  }

  // private addItems(arr: SearchItem[]): SearchItem[] {
  //   const newArr = [];
  //   for (let i = arr.length; i < 1000; i++) {
  //     newArr.push(arr[i % arr.length]);
  //   }
  //   return newArr;
  // }

  private searchItemDataFromDTO(data: ISearchItemDataDTO): SearchItemData {
    return {
      name: data.item_name,
      imgSrc: data.item_image_url || [],
      category: data.category,
      subcategory: data.subcategory,
      price: data.price,
      sellerLink: data.seller_link,
    };
  }

  private additionalInfoFromDTO(
    info: ISearchItemAdditionalInfoDTO,
    sellerInfo: SellerInfo
  ): SearchItemAdditionalData {
    return {
      description: info.item_description,
      sellerId: info.seller_id,
      sellerInfo: sellerInfo,
    };
  }

  private searchSellersInfoFromDTO(data: { [key: string]: ISellerInfoDTO }): {
    [key: string]: SellerInfo;
  } {
    return Object.keys(data).reduce(
      (sellersInfo, currId) => ({
        ...sellersInfo,
        [currId]: this.sellerInfoFromDTO(data[currId]),
      }),
      {}
    );
  }

  private sellerInfoFromDTO(info: ISellerInfoDTO): SellerInfo {
    return {
      imgUrl: info.seller_image_url,
      name: info.seller_name,
      additionalInfo: info.seller_add_info,
      contacts: info.seller_contacts.map(contact =>
        this.sellerContactFromDTO(contact)
      ),
      verified: info.verified,
      sellerAnalytics: this.sellerAnalysisFromDTO(info.seller_analytics),
      dropshipping: info.dropshipping,
      marketEntryDate: info.market_entry_date
        ? new Date(info.market_entry_date)
        : null,
    };
  }

  private sellerContactFromDTO(contact: ISellerContactDTO): SellerContact {
    return {
      snType: contact.sn_type,
      snUrl: contact.sn_url,
      snImageUrl: contact.sn_image_url,
    };
  }

  private analysisItemInfoFromDTO(
    info: IAnalysisItemInfoDTO
  ): SearchItemAnalysisInfo {
    return {
      priceMpComparisonItem: info.price_mp_comparison_item
        ? Object.keys(info.price_mp_comparison_item).reduce(
            (result, key) => ({
              ...result,
              [key]: {
                toMpMax: info.price_mp_comparison_item[key].to_max,
                toMpMean: info.price_mp_comparison_item[key].to_mean,
                toMpMin: info.price_mp_comparison_item[key].to_min,
                logo: info.price_mp_comparison_item[key].logo,
              },
            }),
            {}
          )
        : null,
      priceChangeItem: info.price_change_item
        ? {
            labels: Object.keys(info.price_change_item).map(dateValue =>
              format(new Date(dateValue), 'd MMM', { locale: ru })
            ),
            data: Object.values(info.price_change_item)?.map(v => v.toString()),
          }
        : null,
      lastUpdateDateItem: info.last_update_date_item,
    };
  }

  private sellerAnalysisFromDTO(data: ISellerAnalysisDTO): ISellerAnalytics {
    return {
      averageCatSupplier: data.average_cat_supplier,
      averageSubcatSupplier: data.average_subcat_supplier,
      totalPositionsSupplier: data.total_positions_supplier,
      meanPriceChangesCategorySupplier:
        data.mean_price_changes_category_supplier,
      meanPriceChangesSubcategorySupplier:
        data.mean_price_changes_subcategory_supplier,
      majorCatSupplier: data.major_cat_supplier,
      majorSubcatSupplier: data.major_subcat_supplier,
      catSubcatScheme: data.seller_cat_scheme,
    };
  }

  private searchTotalAnalysisFromSTO(
    data: ISearchTotalAnalysisDTO
  ): ISearchTotalAnalysis {
    return {
      averagePrice: data.average_price,
      averagePriceCategory: data.average_price_category,
      averagePriceSubcategory: data.average_price_subcategory,
      closestQueries: data.closest_queries,
      approximateMargin: Object.keys(data.approximate_margin).reduce(
        (result, key) => ({
          ...result,
          [key]: {
            profitPercent: data.approximate_margin[key].profit_percent,
            profitValue: data.approximate_margin[key].profit_value,
            logo: data.approximate_margin[key].logo,
          },
        }),
        {}
      ),
      extremumMpPrice: Object.keys(data.extremum_mp_price).reduce(
        (result, key) => ({
          ...result,
          [key]: {
            maxPrice: data.extremum_mp_price[key].max_price,
            minPrice: data.extremum_mp_price[key].min_price,
            meanPrice: data.extremum_mp_price[key].mean_price,
          },
        }),
        {}
      ),
      categoriesFreq: data.categories_freq,
      subcategoriesFreq: data.subcategories_freq,
    };
  }
}
