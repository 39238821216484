<div class="seller" *ngIf="sellerInfo">
  <div class="seller__container">
    <div class="seller__img">
      <img
        [src]="sellerInfo.imgUrl || 'assets/images/avatar.png'"
        [alt]="sellerInfo.name || 'Поставщик'" />
    </div>
    <div class="seller__info">
      <!-- <div
        class="seller__name"
        tuiHint="Проверенный поставщик"
        [tuiHintDirection]="'top'"
        [tuiHintAppearance]="'onDark'"> -->
      <div class="seller__name">
        {{ sellerInfo.name || 'Поставщик' }}
        <tui-tag
          *ngIf="sellerInfo.verified"
          status="custom"
          [value]="'Verified'"
          class="verified-tag"
          size="s"></tui-tag>
      </div>
      <div class="seller__from-date" *ngIf="!!sellerInfo.marketEntryDate">
        <!-- <app-icon
          [iconSrc]="'assets/images/check-in-circle.svg'"
          [size]="'s'"
          class="tui-space_right-1"></app-icon> -->
        {{ 'На рынке с ' + (sellerInfo.marketEntryDate | date: 'd.MM.yyyy') }}
      </div>
      <!-- <div class="seller__networks">
        <a
          *ngFor="let contact of sellerInfo.contacts"
          [href]="contact.snUrl"
          target="_blank"
          rel="nofollow noopener">
          <img class="social-icon" [src]="contact.snImageUrl" />
        </a>
      </div> -->
    </div>
  </div>
  <p
    class="seller__additional-info"
    [innerText]="sellerInfo.additionalInfo"></p>
  <app-seller-analytics
    [data]="sellerInfo.sellerAnalytics"></app-seller-analytics>
</div>
