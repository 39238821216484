<a [href]="'/'" class="logo">
  <img src="assets/images/logo.png" />
  <span class="logo__title">Altmetrics</span>
</a>

<div class="user-info" *ngIf="!isTgWebApp && (isLoggedIn$ | async)">
  <app-user-info *ngIf="userData$ | async as userData" [userData]="userData">
  </app-user-info>
  <button
    tuiIconButton
    type="button"
    appearance="icon"
    icon="tuiIconLogOutLarge"
    size="s"
    [tuiHint]="'Выйти'"
    (click)="logout()"></button>
</div>
