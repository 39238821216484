export const DEFAULT_CHART_COLORS = [
  '#0a84ff',
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#1AB394',
  '#F7464A',
  '#9B59B6',
  '#FDB45C',
  '#27AE60',
  '#E67E22',
  '#3498DB',
  '#E74C3C',
  '#8E44AD',
  '#2ECC71',
  '#FF6384',
  '#FFD700',
  '#7F8C8D',
  '#1ABC9C',
  '#FF5733',
  '#5DADE2',
  '#FFA07A',
  '#7FB3D5',
  '#FFCC99',
  '#85C1E9',
  '#FFB6C1',
  '#7DCEA0',
  '#F0B27A',
  '#ABEBC6',
  '#F1948A',
  '#EBDEF0',
  '#82E0AA',
  '#F5CBA7',
  '#D7BDE2',
  '#BB8FCE',
  '#A3E4D7',
  '#E59866',
  '#A9CCE3',
  '#FADBD8',
  '#A2D9CE',
  '#EDBB99',
  '#A2D9CE',
  '#FCF3CF',
  '#A3E4D7',
  '#E59866',
  '#BB8FCE',
  '#D7BDE2',
  '#F5CBA7',
  '#82E0AA',
];
