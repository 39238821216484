import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AnalyticsEvent } from './analytics-event.type';
import { IAnalyticsService } from './analytics-service.interface';

// eslint-disable-next-line no-var
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements IAnalyticsService {
  constructor() {}

  public initialize() {
    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.googleAnalyticsId}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsId}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  public event(action: AnalyticsEvent, params?: { [key: string]: string }) {
    if (typeof gtag === 'undefined') return;
    gtag(
      'event',
      action,
      params && {
        ...params,
      }
    );
  }
}
