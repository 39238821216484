<div class="analytics-container" *ngIf="data">
  <app-average-price-category-card
    *ngIf="data"
    [title]="'Распределение цен'"
    [tooltip]="'Распределение средних цен на товары в зависимости от категории'"
    [categories]="data.averageCatSupplier"
    [subcategories]="data.averageSubcatSupplier"
    [scheme]="data.catSubcatScheme"></app-average-price-category-card>
  <app-one-metric-card
    [title]="'Товаров у поставщика'"
    [tooltip]="'Количество товаров у поставщика'"
    [metric]="data.totalPositionsSupplier"></app-one-metric-card>

  <!-- <app-mean-price-changes-card
    [categories]="data.meanPriceChangesCategorySupplier"
    [subcategories]="data.meanPriceChangesSubcategorySupplier"
    [scheme]="data.catSubcatScheme"></app-mean-price-changes-card> -->

  <app-freq-distribution-card
    [tooltip]="'Распределение товаров поставщика по категориям'"
    [categories]="data.majorCatSupplier"
    [subcategories]="data.majorSubcatSupplier"
    [scheme]="data.catSubcatScheme"></app-freq-distribution-card>
</div>
