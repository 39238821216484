import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  PLATFORM_ID,
} from '@angular/core';
import { SearchService } from '../../services/search.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TuiSheetOptions } from '@taiga-ui/addon-mobile';
import { SearchParamsService } from '../../services/search-params.service';
import {
  ISearchTotalAnalysis,
  SearchFiltersPreset,
  SearchResult,
  TSearchResultSort,
} from '../../models/search-result.model';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  withLatestFrom,
} from 'rxjs';
import { EventType, Params, Router } from '@angular/router';
import { SearchResultsViewModel } from '../search-results/search-results.component';
import { TuiAlertService, TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { WINDOW } from '@ng-web-apis/common';
import { DESKTOP_MIN_SCREEN_WIDTH } from 'src/app/core/constants/common.constant';
import { SearchResultAnalyticsDesktopComponent } from '../search-result-analytics-desktop/search-result-analytics-desktop.component';
import { TelegramService } from 'src/app/telegram/services/telegram/telegram.service';
import { isPlatformServer } from '@angular/common';
import { SearchDislikeComponent } from '../search-dislike/search-dislike.component';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalysisLoginComponent } from 'src/app/analysis/components/analysis-login/analysis-login.component';

@UntilDestroy()
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  isLoading$ = this.searchService.isLoading$;
  result$ = this.searchService.result$;
  isLoggedIn$ = this.authService.isLoggedIn$;
  isTgWebApp = this.telegramService.isTgWebApp();

  isServer = isPlatformServer(this.platformId);

  filtersDropdownOpen = false;
  sortingDropdownOpen = false;

  textFromQueryParams$ = this.searchService.textFromQueryParams$;

  hasFilters$: Observable<boolean> = this.result$.pipe(
    map(result => {
      const availableFilters = result.filters?.availableFilters;
      return (
        !!availableFilters &&
        ((availableFilters.catAndSubcat &&
          Object.keys(availableFilters.catAndSubcat).length > 0) ||
          (availableFilters.maxMinPrices &&
            availableFilters.maxMinPrices.max !== 0))
      );
    })
  );

  filtersSheetOpen = false;
  analyticsOpen = false;
  readonly sheetOptions: Partial<TuiSheetOptions> = {
    overlay: true,
  };

  private readonly queryParams$: Observable<Params> =
    this.searchParamsService.getQueryParams();

  public readonly preparedResult$: Observable<SearchResultsViewModel> =
    this.queryParams$.pipe(
      withLatestFrom(
        this.result$.pipe(map(result => result.searchResult || []))
      ),
      map(([params, items]) => {
        const {
          category,
          subcategories,
          sort,
          text,
          withPhotos,
          priceRange,
          onlyDropshipping,
          recentItemsOnly,
        } = params;

        return {
          results: [...items],
          filters: {
            category,
            subcategories,
            withPhotos,
            priceRange,
            onlyDropshipping,
            recentItemsOnly,
          },
          sort: sort || 'asc',
          text: text || '',
        };
      })
    );

  public readonly selectedCatSubcatFilters$ = this.preparedResult$.pipe(
    map(result =>
      result.filters.subcategories?.length > 0
        ? result.filters.subcategories
        : result.filters.category
          ? [result.filters.category]
          : []
    )
  );

  public readonly selectedWithPhotoFilter$ = this.preparedResult$.pipe(
    map(result => result.filters.withPhotos)
  );

  public readonly selectedPriceRangeFilter$ = this.preparedResult$.pipe(
    map(result => result.filters.priceRange)
  );

  public readonly selectedDropFilter$ = this.preparedResult$.pipe(
    map(result => result.filters.onlyDropshipping)
  );

  public readonly selectedRecentItemsFilter$ = this.preparedResult$.pipe(
    map(result => result.filters.recentItemsOnly)
  );

  public selectedFiltersCount$ = combineLatest(
    this.selectedCatSubcatFilters$,
    this.selectedWithPhotoFilter$,
    this.selectedPriceRangeFilter$,
    this.selectedDropFilter$,
    this.selectedRecentItemsFilter$
  ).pipe(
    map(
      ([catAndSubcat, withPhoto, priceRange, onlyDrop, recentItems]) =>
        catAndSubcat.length +
        +withPhoto +
        +!!priceRange +
        +onlyDrop +
        +recentItems
    )
  );

  showFeedbackBtn = false;

  constructor(
    private readonly searchService: SearchService,
    private readonly authService: AuthService,
    private readonly searchParamsService: SearchParamsService,
    private readonly telegramService: TelegramService,
    private readonly router: Router,
    @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly analyticsService: AnalyticsService,
    private readonly alertService: TuiAlertService
  ) {
    router.events.subscribe(event => {
      if (
        event.type === EventType.NavigationStart &&
        event.navigationTrigger === 'popstate'
      ) {
        this.updateSearch();
      }
    });

    this.result$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged((prev, curr) => prev.searchId === curr.searchId)
      )
      .subscribe(result => {
        if (result.searchResult?.length) {
          this.showFeedbackBtn = true;
        }
      });
  }

  getFoundItemsTextEnd(itemsFound: number): string {
    const n = Math.abs(itemsFound) % 100;
    const n1 = n % 10;
    let end = ' товаров';

    if (n1 > 1 && n1 < 5 && n > 15) {
      end = ' товара';
    } else if (n1 == 1) {
      end = ' товар';
    }
    return end;
  }

  private showAnalyticsDialog(
    analytics: ISearchTotalAnalysis,
    scheme: { [key: string]: Array<string> }
  ): void {
    const analyticsDialog = this.dialogs.open<string | null>(
      new PolymorpheusComponent(
        SearchResultAnalyticsDesktopComponent,
        this.injector
      ),
      {
        data: {
          analytics,
          scheme,
        },
        dismissible: true,
        closeable: false,
        size: 'auto',
      }
    );
    analyticsDialog.subscribe(tagText => {
      if (tagText) {
        this.searchService.newSearch(tagText, true);
      }
    });
  }

  onEdited(filter: string, currentFilters: SearchFiltersPreset): void {
    const newFiltersPreset: Partial<SearchFiltersPreset> =
      currentFilters.subcategories?.length === 1
        ? {
            subcategories: [],
            category: null,
          }
        : currentFilters.subcategories?.length > 0
          ? {
              subcategories: currentFilters.subcategories.filter(
                item => item !== filter
              ),
              category: currentFilters.category,
            }
          : {
              subcategories: [],
              category: null,
            };

    if (currentFilters.subcategories?.length > 0) {
      this.analyticsService.sendFilterClearingClicked('sub_category');
    } else {
      this.analyticsService.sendFilterClearingClicked('category');
    }

    this.updateFilters({
      ...currentFilters,
      ...newFiltersPreset,
    } as SearchFiltersPreset);
  }

  onWithPhotoEdited(currentFilters: SearchFiltersPreset): void {
    this.updateFilters({
      ...currentFilters,
      withPhotos: false,
    } as SearchFiltersPreset);
    this.analyticsService.sendFilterClearingClicked('hide_without_photo');
  }

  onPriceRangeEdited(currentFilters: SearchFiltersPreset): void {
    this.updateFilters({
      ...currentFilters,
      priceRange: null,
    } as SearchFiltersPreset);
    this.analyticsService.sendFilterClearingClicked('price');
  }

  onDropOnlyEdited(currentFilters: SearchFiltersPreset): void {
    this.updateFilters({
      ...currentFilters,
      onlyDropshipping: false,
    } as SearchFiltersPreset);
    this.analyticsService.sendFilterClearingClicked('drop_shipping');
  }

  onRecentItemsEdited(currentFilters: SearchFiltersPreset): void {
    this.updateFilters({
      ...currentFilters,
      recentItemsOnly: false,
    } as SearchFiltersPreset);
    this.analyticsService.sendFilterClearingClicked('last_month_items');
  }

  onRemoveAllFilters(): void {
    this.updateFilters({
      subcategories: [],
      category: '',
      withPhotos: false,
      priceRange: null,
      recentItemsOnly: false,
      onlyDropshipping: false,
    });
    this.analyticsService.sendFilterClearingClicked('all');
  }

  onSearch(text: string, prevText: string) {
    this.searchService.newSearch(text, text !== prevText);
  }

  openAnalyticsLogin(
    analytics: ISearchTotalAnalysis,
    scheme: { [key: string]: Array<string> }
  ): void {
    const dialog = this.dialogs.open<boolean>(
      new PolymorpheusComponent(AnalysisLoginComponent, this.injector),
      {
        dismissible: true,
        closeable: false,
        size: 's',
      }
    );
    dialog.subscribe(result => {
      if (!result) return;

      this.openAnalytics(analytics, scheme);
    });
  }

  openAnalytics(
    analytics: ISearchTotalAnalysis,
    scheme: { [key: string]: Array<string> }
  ) {
    if (this.window.innerWidth >= DESKTOP_MIN_SCREEN_WIDTH) {
      this.showAnalyticsDialog(analytics, scheme);
    } else {
      this.analyticsOpen = true;
    }
  }

  toggleFiltersSheet(): void {
    this.filtersSheetOpen = !this.filtersSheetOpen;
  }

  updateFilters(selectedFilters: SearchFiltersPreset): void {
    const {
      category,
      subcategories,
      withPhotos,
      priceRange,
      onlyDropshipping,
      recentItemsOnly,
    } = selectedFilters;

    this.searchParamsService.updateQueryParams({
      category: JSON.stringify(category),
      subcategories: JSON.stringify(subcategories),
      withPhotos: JSON.stringify(withPhotos),
      priceRange: JSON.stringify(priceRange),
      onlyDropshipping: JSON.stringify(onlyDropshipping),
      recentItemsOnly: JSON.stringify(recentItemsOnly),
    });
    this.searchService.updatePage(1);
    this.analyticsService.sendFilterChangedEvent(
      category || '',
      JSON.stringify(subcategories), // TODO
      priceRange?.min || 0,
      priceRange?.max || 0,
      withPhotos,
      onlyDropshipping,
      recentItemsOnly
    );
  }

  onUpdateSorting(sort: TSearchResultSort): void {
    this.searchParamsService.updateQueryParams({
      sort: sort === 'asc' ? 'desc' : 'asc',
    });
    this.searchService.updatePage(1);
    this.analyticsService.sendSortingChangedEvent(
      sort === 'asc' ? 'price_desc' : 'price_asc'
    );
  }

  onUpdateSortingDesktop(sort: TSearchResultSort): void {
    this.onUpdateSorting(sort);
    this.sortingDropdownOpen = false;
    this.analyticsService.sendSortingChangedEvent(
      sort === 'asc' ? 'price_asc' : 'price_desc'
    );
  }

  onCloseFilters(): void {
    this.filtersDropdownOpen = false;
    this.filtersSheetOpen = false;
  }

  onCloseAnalytics(): void {
    this.analyticsOpen = false;
  }

  onLike(result: SearchResult): void {
    this.analyticsService.sendSearchReportPositiveEvent(
      result.searchText,
      result.page,
      result.itemsFound
    );
    this.showFeedbackBtn = false;
    this.sendLikeDislikeSuccessMsg();
  }

  onDislike(result: SearchResult): void {
    const dislikeDialog = this.dialogs.open<string | null>(
      new PolymorpheusComponent(SearchDislikeComponent, this.injector),
      {
        data: {},
        dismissible: true,
        closeable: false,
        size: 'auto',
        appearance: 'dislike-dialog',
      }
    );

    dislikeDialog.subscribe(text => {
      if (text) {
        this.analyticsService.sendSearchReportNegativeEvent(
          result.searchText,
          result.page,
          result.itemsFound,
          text
        );
        this.showFeedbackBtn = false;
        this.sendLikeDislikeSuccessMsg();
      }
    });
  }

  sendLikeDislikeSuccessMsg(): void {
    this.alertService
      .open('Cпасибо за ваш отзыв', {
        status: 'success',
        autoClose: 3000,
      })
      .subscribe();
  }

  updateSearch(): void {
    this.searchService.updateSearch();
  }
}
