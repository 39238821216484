import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, take, map, catchError, of } from 'rxjs';
import { ILoginResponse } from '../models/silent-login-response.model';
import * as jwtDecode from 'jwt-decode';
import { IUserData } from '../models/user-data.model';
import { UserDataMapper } from './user-data.mapper';
import { IUserDataDTO } from '../models/user-data-dto.model';
import { TelegramService } from 'src/app/telegram/services/telegram/telegram.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Injectable()
export class AuthService {
  private jwtSubject = new BehaviorSubject<string | undefined>('');
  private readonly apiUrl = environment.apiURL;

  public jwt$ = this.jwtSubject.asObservable();
  public isLoggedIn$ = this.jwt$.pipe(
    map(jwt => (jwt === undefined ? undefined : !!jwt))
  );
  public userData$ = this.jwt$.pipe(map(jwt => this.getUserDataFromJWT(jwt)));

  private isAuthCheckedSubject = new BehaviorSubject<boolean>(false);
  public isAuthChecked$ = this.isAuthCheckedSubject.asObservable();

  public get jwt(): string | undefined {
    return this.jwtSubject.getValue();
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly userDataMapper: UserDataMapper,
    private readonly telegramService: TelegramService,
    private readonly analyticsService: AnalyticsService
  ) {}

  checkJwt(jwt: string): void {
    if (!!this.jwt && !jwt) {
      this.logout();
      return;
    }

    if (jwt) {
      this.jwtSubject.next(jwt);
    }
  }

  auth(userData: WebAppUser & { hash: string } & { auth_date: string }): void {
    this.httpClient
      .post<ILoginResponse>(`${this.apiUrl}/auth`, {
        id: userData.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        username: userData.username,
        photo_url: userData.photo_url,
        auth_date: +userData.auth_date, // TODO fix this type
        hash: userData.hash,
      })
      .pipe(
        take(1),
        map(({ Authorization }: ILoginResponse) => {
          this.analyticsService.sendLoginSuccessEvent();
          this.jwtSubject.next(Authorization);
          this.isAuthCheckedSubject.next(true);
        })
      )
      .subscribe();
  }

  authApp(data: string): void {
    this.httpClient
      .post<ILoginResponse>(`${this.apiUrl}/auth_app`, {
        auth_data: data,
      })
      .pipe(
        take(1),
        map(({ Authorization }: ILoginResponse) => {
          this.analyticsService.sendLoginSuccessEvent();
          this.jwtSubject.next(Authorization);
          this.isAuthCheckedSubject.next(true);
        })
      )
      .subscribe();
  }

  silentLogin(): Observable<ILoginResponse> {
    return this.httpClient.get<ILoginResponse>(`${this.apiUrl}/silent_login`);
  }

  checkLogin() {
    if (this.jwt) {
      return;
    }

    if (this.telegramService.isTgWebApp()) {
      this.authApp(this.telegramService.getInitData()!);
      return;
    }

    this.silentLogin()
      .pipe(
        take(1),
        map(({ Authorization }: ILoginResponse) => {
          this.jwtSubject.next(Authorization);
          this.isAuthCheckedSubject.next(true);
        }),
        catchError(error => {
          this.jwtSubject.next('');

          const tgUserData = this.telegramService.getUserData();
          const hash = this.telegramService.getTgHash()!;
          const auth_date = this.telegramService.getAuthDate()!;

          if (tgUserData) {
            this.auth({ ...tgUserData, hash, auth_date });
          } else {
            this.telegramService
              .getLinkURLAuthData()
              .pipe(take(1))
              .subscribe(data => {
                if (data) {
                  this.auth(data);
                } else {
                  this.isAuthCheckedSubject.next(true);
                }
              });
          }
          return of(error);
        })
      )
      .subscribe();
  }

  logout(): void {
    this.httpClient
      .post(`${this.apiUrl}/logout`, {})
      .pipe(take(1))
      .subscribe(() => {
        this.jwtSubject.next('');
      });
  }

  private getUserDataFromJWT(jwt: string | undefined): IUserData | null {
    if (!jwt) return null;

    try {
      const jwtPayload = jwtDecode.jwtDecode(jwt) as IUserDataDTO;
      return this.userDataMapper.fromDTO(jwtPayload);
    } catch (err) {
      return null;
    }
  }
}
