import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { TuiPreviewDialogService } from '@taiga-ui/addon-preview';
import { tuiClamp } from '@taiga-ui/cdk';
import { TuiDialogContext } from '@taiga-ui/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-search-item-images',
  templateUrl: './search-item-images.component.html',
  styleUrls: ['./search-item-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchItemImagesComponent {
  @Input() images: Array<string> = [];
  @Input() alt = '';

  caruselIndex = 0;
  sliderIndex = 0;

  @ViewChild('preview')
  readonly preview?: TemplateRef<TuiDialogContext>;

  get previewContent(): PolymorpheusContent {
    return this.images[this.sliderIndex];
  }

  get previewImages(): Array<string> {
    return this.images.slice(0, 4);
  }

  constructor(
    @Inject(TuiPreviewDialogService)
    private readonly previewService: TuiPreviewDialogService,
    @Inject(WINDOW) private window: Window
  ) {}

  showPhotos(event: MouseEvent) {
    if (this.images?.length === 0) return;
    event.stopPropagation();

    this.sliderIndex = this.caruselIndex;
    this.previewService.open(this.preview || '').subscribe({
      complete: () => console.info('complete'),
    });
  }

  onSwipe(swipe: any): void {
    const length = this.images.length;
    if (swipe.direction === 'left') {
      this.sliderIndex = tuiClamp(this.sliderIndex + 1, 0, length - 1);
    }

    if (swipe.direction === 'right') {
      this.sliderIndex = tuiClamp(this.sliderIndex - 1, 0, length - 1);
    }
  }

  onPreviewClick(event: MouseEvent) {
    if ((event.target as any).nodeName !== 'TUI-PREVIEW') {
      return;
    }
    if (event.x < this.window.innerWidth / 2) {
      if (this.sliderIndex > 0) {
        this.sliderIndex -= 1;
      }
    } else if (this.sliderIndex < this.images.length - 1) {
      this.sliderIndex += 1;
    }
  }
}
