<h2>
  <label>Аналитика</label>
  <button
    tuiIconButton
    type="button"
    appearance="icon"
    size="m"
    (click)="onCloseAnalytics()">
    <app-icon
      [iconSrc]="'assets/images/iconCloseLarge.svg'"
      [size]="'s'"></app-icon>
  </button>
</h2>
<app-search-result-analytics
  *ngIf="data"
  [data]="data"
  [scheme]="scheme"
  (newSearch)="onSearch($event)"
  (closeAnalitics)="onCloseAnalytics()"></app-search-result-analytics>
