import { Injectable } from '@angular/core';

const COOKIE_SESSION_KEY = 'is_cookies_accepted';

@Injectable()
export class CookieService {
  constructor() {}

  public needToShowCookiePush(): boolean {
    const isCookieAccepted = sessionStorage.getItem(COOKIE_SESSION_KEY);
    return isCookieAccepted ? !JSON.parse(isCookieAccepted) : true;
  }

  public acceptCookie(): void {
    sessionStorage.setItem(COOKIE_SESSION_KEY, JSON.stringify(true));
  }
}
