<form [formGroup]="formGroup" class="filters-form">
  <label
    tuiLabel="По категории"
    class="filter tui-space_bottom-6"
    *ngIf="!!categories?.length">
    <tui-select
      tuiTextfieldSize="s"
      [formControlName]="'category'"
      [tuiActiveZoneParent]="activeZone"
      [tuiTextfieldCleaner]="true">
      Выберите категории
      <tui-data-list *tuiDataList>
        <button
          *ngFor="let category of categories"
          tuiOption
          [value]="category">
          {{ category }}
        </button>
      </tui-data-list>
    </tui-select>
  </label>
  <label
    tuiLabel="По подкатегории"
    *ngIf="!!subcategories?.length"
    class="filter tui-space_bottom-6">
    <tui-multi-select
      tuiTextfieldSize="s"
      [editable]="false"
      [formControlName]="'subcategories'"
      [tuiActiveZoneParent]="activeZone"
      [tuiTextfieldCleaner]="true">
      Выберите подкатегории
      <tui-data-list *tuiDataList tuiMultiSelectGroup>
        <button *ngFor="let subcat of subcategories" tuiOption [value]="subcat">
          {{ subcat }}
        </button>
      </tui-data-list>
    </tui-multi-select>
  </label>
  <label tuiLabel="Цена" class="filter tui-space_bottom-6" *ngIf="!!max">
    <tui-range
      [formControlName]="'priceRange'"
      [max]="max"
      [min]="min"
      [tuiTextfieldLabelOutside]="true"
      [tuiTextfieldSize]="'m'"></tui-range>
    <div class="price-range-inputs">
      <tui-input-number
        [formControlName]="'from'"
        tuiTextfieldPostfix="руб"
        [precision]="0"
        tuiTextfieldSize="s"
        [focusable]="false"
        [min]="min"
        [max]="maxFrom">
        От
        <input name="from" tuiTextfield />
      </tui-input-number>
      <tui-input-number
        [formControlName]="'to'"
        tuiTextfieldPostfix="руб"
        [precision]="0"
        tuiTextfieldSize="s"
        [focusable]="false"
        [min]="minTo"
        [max]="max">
        До
        <input name="to" tuiTextfield />
      </tui-input-number>
    </div>
  </label>
  <label tuiLabel="Скрыть товары без фото" class="filter filter--row">
    <tui-toggle formControlName="withPhotos" size="l"></tui-toggle>
  </label>
  <label tuiLabel="Дропшиппинг" class="filter filter--row">
    <tui-toggle formControlName="onlyDropshipping" size="l"></tui-toggle>
  </label>
  <label tuiLabel="Товары за последний месяц" class="filter filter--row">
    <tui-toggle formControlName="recentItemsOnly" size="l"></tui-toggle>
  </label>
</form>
<div class="filters-footer">
  <button
    class="apply-btn"
    tuiButton
    type="button"
    size="m"
    appearance="custom"
    (click)="applyFilters()">
    Применить
  </button>
</div>
