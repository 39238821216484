<tui-block-status>
  <img
    tuiSlot="top"
    alt="Товар не найден"
    src="./assets/images/search-item-not-found.svg"
    class="image" />

  <h4>Товар не найден</h4>

  <p>
    Попробуйте написать название товара по-другому или сократить запрос.
    Убедитесь, что название бренда и модели написано правильно.
  </p>
</tui-block-status>
