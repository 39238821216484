import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-search-dislike',
  templateUrl: './search-dislike.component.html',
  styleUrl: './search-dislike.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDislikeComponent {
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string | null, {}>
  ) {}

  predefinedOptions = [
    'Мало товаров в выдаче',
    'Много лишних товаров',
    'Высокая цена',
    'Плохое описание товаров',
  ];

  customOption = 'Свой вариант';

  showTextarea = false;

  form = new FormGroup({
    customText: new FormControl('', Validators.required),
  });

  onCustomOptionClick(): void {
    this.showTextarea = !this.showTextarea;
  }

  sendFeedback(text: string): void {
    this.context.completeWith(text);
  }

  sendCustomFeedback(): void {
    const text = this.form.value.customText;
    if (!text) return;
    this.sendFeedback(text);
  }
}
