import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchComponent } from './search/components/search/search.component';
import { MainPageComponent } from './search/components/main-page/main-page.component';

const routes: Routes = [
  { path: 'search', component: SearchComponent },
  { path: '', component: MainPageComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
