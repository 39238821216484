import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISellerAnalytics } from '../../models/seller.model';

@Component({
  selector: 'app-seller-analytics',
  templateUrl: './seller-analytics.component.html',
  styleUrls: ['./seller-analytics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellerAnalyticsComponent {
  @Input() data: ISellerAnalytics;
}
