import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { TELEGRAM_LOGIN_WIDGET_CONFIG } from '../../telegram.constants';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Component({
  selector: 'app-telegram-login-widget',
  templateUrl: './telegram-login-widget.component.html',
  styleUrls: ['./telegram-login-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramLoginWidgetComponent implements AfterViewInit {
  @ViewChild('widgetContainer', { static: true }) scriptContainer: ElementRef;

  @Output() login = new EventEmitter<
    WebAppUser & { hash: string } & { auth_date: string }
  >();

  constructor(
    private readonly ngZone: NgZone,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private window: Window,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngAfterViewInit() {
    const script = this.document.createElement('script');

    Object.keys(TELEGRAM_LOGIN_WIDGET_CONFIG).forEach(key => {
      script.setAttribute(key, TELEGRAM_LOGIN_WIDGET_CONFIG[key]);
    });

    this.window.onTelegramLogin = (
      data: WebAppUser & { hash: string } & { auth_date: string }
    ) => {
      this.ngZone.run(() => {
        this.analyticsService.sendLoginClickEvent();
        this.login.emit(data);
      });
    };

    this.scriptContainer.nativeElement.innerHTML = '';
    this.scriptContainer.nativeElement.appendChild(script);
  }
}
