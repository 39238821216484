import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-freq-distribution-card',
  templateUrl: './freq-distribution-card.component.html',
  styleUrls: ['./freq-distribution-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreqDistributionCardComponent implements OnInit {
  @Input() categories: { [key: string]: number };
  @Input() subcategories: { [key: string]: number };
  @Input() scheme: { [key: string]: Array<string> };
  @Input() tooltip: string;

  activeItemIndex = 0;
  chartData: { [key: string]: number } = {};

  public get categoriesLabels(): Array<string> {
    return Object.keys(this.categories);
  }

  ngOnInit() {
    this.chartData = this.categories;
  }

  onIndexChanged(index: number) {
    if (this.activeItemIndex === 0) {
      this.chartData = this.categories;
      return;
    }

    const category = this.categoriesLabels[index - 1];
    const subcategoriesLabels = this.scheme[category] || [];
    const currentSubcategories = Object.entries(this.subcategories).filter(
      ([subcategory, value]) => subcategoriesLabels.includes(subcategory)
    );

    this.chartData = Object.fromEntries(currentSubcategories);
  }
}
