import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AccessInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.authService.jwt) return next.handle(req);

    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `${this.authService.jwt}`),
      withCredentials: true,
    });

    return next.handle(modifiedReq);
  }
}
