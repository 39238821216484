import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';
import { YandexAnalyticsService } from './yandex-analytics.service';
import { IAnalyticsService } from './analytics-service.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  analyticsServices: Array<IAnalyticsService> = [
    this.googleAnalyticsService,
    this.yandexAnalyticsService,
  ];

  constructor(
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly yandexAnalyticsService: YandexAnalyticsService
  ) {}

  sendOpenPageEvent(pageNumber: number): void {
    this.analyticsServices.forEach(service => {
      service.event('change_page', {
        page_number: pageNumber.toString(),
      });
    });
  }

  sendExpandItemEvent(pageNumber: number, itemNumber: number): void {
    this.analyticsServices.forEach(service => {
      service.event('expand_item', {
        page_number: pageNumber.toString(),
        item_number: itemNumber.toString(),
      });
    });
  }

  sendCollapseItemEvent(pageNumber: number, itemNumber: number): void {
    this.analyticsServices.forEach(service => {
      service.event('collapse_item', {
        page_number: pageNumber.toString(),
        item_number: itemNumber.toString(),
      });
    });
  }

  sendOpenSupplierViewEvent(
    pageNumber: number,
    itemNumber: number,
    supplierNumber: string,
    supplierName: string
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('open_supplier_view', {
        page_number: pageNumber.toString(),
        item_number: itemNumber.toString(),
        supplier_number: supplierNumber.toString(),
        supplier_name: supplierName,
      });
    });
  }

  sendOpenSupplierContactEvent(
    pageNumber: number,
    itemNumber: number,
    supplierNumber: string,
    supplierName: string
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('open_supplier_contact', {
        page_number: pageNumber.toString(),
        item_number: itemNumber.toString(),
        supplier_number: supplierNumber.toString(),
        supplier_name: supplierName,
      });
    });
  }

  sendCopySupplierMsgEvent(
    pageNumber: number,
    itemNumber: number,
    supplierNumber: string,
    supplierName: string
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('copy_supplier_msg', {
        page_number: pageNumber.toString(),
        item_number: itemNumber.toString(),
        supplier_number: supplierNumber.toString(),
        supplier_name: supplierName,
      });
    });
  }

  sendSearchReportPositiveEvent(
    searchText: string,
    pageNumber: number,
    itemsCount: number
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('search_report_positive', {
        search_text: searchText, //строка поиска,
        page_number: pageNumber.toString(), //индекс открытой страницы,
        items_count: itemsCount.toString(), //количество товаров в выдаче,
      });
    });
  }

  sendSearchReportNegativeEvent(
    searchText: string,
    pageNumber: number,
    itemsCount: number,
    report: string
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('search_report_negative', {
        search_text: searchText, //строка поиска,
        page_number: pageNumber.toString(), //индекс открытой страницы,
        items_count: itemsCount.toString(), //количество товаров в выдаче,
        report: report, //предефайнед вариант (текстом) или кастомный текст
      });
    });
  }

  sendLoginClickEvent(): void {
    this.analyticsServices.forEach(service => {
      service.event('login_click', {});
    });
  }

  sendLoginSuccessEvent(): void {
    this.analyticsServices.forEach(service => {
      service.event('login_success', {});
    });
  }

  sendSortingChangedEvent(mode: 'price_asc' | 'price_desc'): void {
    this.analyticsServices.forEach(service => {
      service.event('sorting_changed', { mode });
    });
  }

  sendFilterChangedEvent(
    category: string,
    subCategory: string,
    minPrice: number,
    maxPrice: number,
    hideWithoutPhoto: boolean,
    dropShipping: boolean,
    lastMonthItems: boolean
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('filter_changed', {
        category: category || '',
        sub_category: subCategory || '',
        min_price: minPrice.toString(),
        max_price: maxPrice.toString(),
        hide_without_photo: `${hideWithoutPhoto}`,
        drop_shipping: `${dropShipping}`,
        last_month_items: `${lastMonthItems}`,
      });
    });
  }

  sendFilterClearingClicked(
    type:
      | 'all'
      | 'category'
      | 'sub_category'
      | 'price'
      | 'hide_without_photo'
      | 'drop_shipping'
      | 'last_month_items'
  ): void {
    this.analyticsServices.forEach(service => {
      service.event('filter_clearing_clicked', {
        type,
      });
    });
  }
}
